import React from "react";
import ChildrenProps from "../model/props/ChildrenProps";

const AppBody: React.FC<ChildrenProps> = (props) => {
  return (
    <React.Fragment>
      <div className={"main"}>
        {props.children}
      </div>
    </React.Fragment>
  )
}

export default AppBody