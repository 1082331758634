import React, {FC} from "react";
import PaymentType from "../../../shared/enum/PaymentType";
import {OrderInput, OrderSpan} from "../../model/order/Order";
import UISelect from "../../../shared/dom/component/UISelect";
import {MSDetailSumCss} from "../confirm/MSDetailSum";
import ProductUtils from "../../../shared/utils/ProductUtils";

type MSDetailSumInputProps = {
  order: OrderInput
}

const MSDetailSumInput: FC<MSDetailSumInputProps> = (props) => {
  const order = props.order

  const buildLabel = (paymentType: PaymentType) => {
    switch (paymentType) {
      case PaymentType.BUYOUT:
        return <th style={MSDetailSumCss.th}>初期費用<br/>（消費税込）</th>
      case PaymentType.SUBSCRIPTION:
        return <th style={MSDetailSumCss.th}>月額費用<br/>（消費税込）</th>
    }
  }

  const css: { [_: string]: React.CSSProperties } = {
    selectCss: {},
  }

  return (
    <div>
      <table style={MSDetailSumCss.table}>
        <tbody>
        {order.details.map(detail => {
          return (
            <tr key={detail.id}>
              {buildLabel(detail.paymentType)}
              <td style={MSDetailSumCss.td}>
                ￥{ProductUtils.formatPriceWithTax(detail.total)}-
              </td>
            </tr>
          )
        })}
        <tr>
          <th style={MSDetailSumCss.th}>見込み有効期限</th>
          <td style={MSDetailSumCss.td}>
            <UISelect style={css.selectCss}
                      default={order.orderSpan}
                      options={[OrderSpan.TWO_WEEK, OrderSpan.FOUR_WEEK]}
                      onChange={(value) => order.orderSpan = value}/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  )
}

export default MSDetailSumInput
