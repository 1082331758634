import Product from "../../main/model/product/Product";
import Summary from "../../main/model/product/summary/Summary";
import Initial from "../../main/model/product/initial/Initial";
import SingleInitialItem from "../../main/model/product/initial/SingleInitialItem";
import Option from "../../main/model/product/option/Option";
import ComparablePlanItem from "../../main/model/product/plan/ComparablePlanItem";
import SelectableOptionItem from "../../main/model/product/option/SelectableOptionItem";
import PaymentType from "../../shared/enum/PaymentType";
import SimpleOptionItem from "../../main/model/product/option/SimpleOptionItem";
import SelectionType from "../../shared/enum/SelectionType";
import SimplePurchasable from "../../shared/model/purchasable/SimplePurchasable";
import SelectablePlan from "../../main/model/product/plan/SelectablePlan";
import {PlanItems} from "../../main/model/product/plan/IPlan";
import SimplePlanItem from "../../main/model/product/plan/SimplePlanItem";
import ProductUtils from "../../shared/utils/ProductUtils";

const imagePath = "/products/liew"

const buildPlan: (id: number, price2y: number, price1y: number) => PlanItems = (id, price2y, price1y) => {
  return new PlanItems(`${id}ID`, [
    new ComparablePlanItem(
      "2年更新プラン",
      `${imagePath}/plan2y.jpg`,
      [`${Math.round(price2y / id)}円/ID`],
      price2y,
      `1年更新プランと比べて年間${ProductUtils.formatPrice((price1y - price2y) * 12)}円おトク`,
      ` ■ システム（Liew）の最低契約期間は、月額費用発生月より2年間となります。`),
    new SimplePlanItem(
      "1年更新プラン",
      `${imagePath}/plan1y.jpg`,
      [`${Math.round(price1y / id)}円/ID`],
      price1y,
      ` ■ システム（Liew）の最低契約期間は、月額費用発生月より1年間となります。`),
  ])
}

const summary = new Summary(
  "Liew",
  "https://www.lacicu.co.jp/wp/wp-content/themes/standard/images/service/service_lineup-liew.jpg",
  "Liew（リュウ）は、志望校への合否を大きく分ける“自宅学習”をサポートする自宅学習用動画アプリケーションです。")

const initial = new Initial([
  new SingleInitialItem(
    "初期設定サポート",
    "",
    `${imagePath}/init01.jpg`,
    100000),
])

const plans = new SelectablePlan([
  buildPlan(30, 8000, 10000),
  buildPlan(50, 12000, 15000),
  buildPlan(100, 20000, 25000),
  buildPlan(200, 36000, 45000),
  buildPlan(300, 48000, 60000),
])

const options = new Option([
  new SelectableOptionItem(
    "Liew for Manager",
    "動画をスマホからアップロードできるサービス",
    `${imagePath}/op01.jpg`,
    [
      new SimplePurchasable("1TB", PaymentType.SUBSCRIPTION, 3000, SelectionType.SINGLE),
      new SimplePurchasable("無制限", PaymentType.SUBSCRIPTION, 5000, SelectionType.SINGLE)
    ]),
  new SimpleOptionItem(
    "オリジナルアプリ",
    "自分の塾のアプリとしてリリース",
    `${imagePath}/op02.jpg`,
    PaymentType.SUBSCRIPTION,
    30000,
    SelectionType.SINGLE,),
])

const PLiew: Product = new Product(summary, initial, plans, options)
export default PLiew