import React, {CSSProperties, FC} from "react";
import StyleProps from "../../model/props/StyleProps";
import UILabel from "./UILabel";
import Logger from "../../Logger";

type UIDateFieldProp = {
  label?: string
  default?: Date
  onChange?: (date: Date) => void
  containerCss?: CSSProperties
  labelCss?: CSSProperties
  inputCss?: CSSProperties
  style?: { [_: string]: React.CSSProperties }
} & StyleProps

const UIDateField: FC<UIDateFieldProp> = (props) => {
  const defaultDateString = (() => {
    if (!props.default) return undefined
    const targetDate = props.default as Date
    const year = targetDate.getFullYear()
    const month = `00${targetDate.getMonth() + 1}`.slice(-2)
    const date = `00${targetDate.getDate()}`.slice(-2)
    return `${year}-${month}-${date}`
  })()

  return (
    <div style={props.containerCss}>
      {props.label && <UILabel style={props.labelCss} label={props.label}/>}
      <div>
        <input style={props.inputCss} type={"date"}
               defaultValue={defaultDateString}
               onChange={(event) => {
                 Logger.debug(`UIDateField.onChange event:{${event}`)
                 props.onChange?.(new Date(Date.parse(event.target.value)))
               }}/>
      </div>
    </div>
  )
}

export default UIDateField