import React from "react";
import ProductUtils from "../../../shared/utils/ProductUtils";
import UIUnit from "../../../shared/dom/component/UIUnit";
import StyleProps from "../../../shared/model/props/StyleProps";

type UIPriceProp = {
  price: number
  suffix?: string
} & StyleProps

const UIPrice: React.FC<UIPriceProp> = (props) => {
  return (
    <React.Fragment>
      <UIUnit tag={"div"} style={props.style}>
        {ProductUtils.formatPrice(props.price, true)}{props.suffix ?? ""}
      </UIUnit>
    </React.Fragment>
  );
}

export default UIPrice