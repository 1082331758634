class Logger {
  static debug(...args: any[]) {
    console.debug(args)
  }

  static info(...args: any[]) {
    console.log(args)
  }

  static error(...args: any[]) {
    console.error(args)
  }
}

export default Logger