import React, {Dispatch, FC, SetStateAction, useState} from "react";
import UIButton from "../../../shared/dom/component/UIButton";
import {OrderInput} from "../../model/order/Order";
import {db} from "../../../shared/config/FirebaseApp";
import {addDoc, collection} from "firebase/firestore";
import {PATHS} from "../../../index";
import {useAuthContext} from "../../../shared/config/GoogleAuthentication";
import {User} from "firebase/auth";
import MSOSCartInput from "../ordersheet/MSOSCartInput";
import MSOSCartPreview from "../ordersheet/MSOSCartIPreview";
import MSOSConfirmPreview from "../ordersheet/MSOSConfirmPreview";
import UITextField from "../../../shared/dom/component/UITextField";
import {OrderMail} from "../../../data/mail/OrderMail";
import {CreateOrderState} from "../MSPageCreateOrder";
import {AppConfig} from "../../../shared/config/AppConfig";
import FirebaseUtils, {OrderStatus} from "../../../shared/utils/FirebaseUtils";
import PdfUtils from "../../../shared/utils/PdfUtils";

type MSOrderSheetProp = {
  order: OrderInput
  setState: Dispatch<SetStateAction<CreateOrderState>>
}

const MSOrderSheet: FC<MSOrderSheetProp> = (props) => {
  const user = useAuthContext() as User
  const order = props.order
  // const order = useContext(OrderContext)
  order.managerName = user.displayName ?? ""
  order.managerEmail = user.email ?? ""

  let [isPreview, setIsPreview] = useState(false)

  async function debugSavePdf() {
    const pdf = await PdfUtils.toPdf("divToPrint")
    pdf?.save("注文書.pdf")
  }

  async function printDocument() {
    props.setState(CreateOrderState.PRINTING)
    const blob = await PdfUtils.toBlob("divToPrint")
    if (!blob) return

    props.setState(CreateOrderState.SAVING)
    const filePath = `${AppConfig.preOrderStorage}/${FirebaseUtils.generateFileName()}.pdf`
    await FirebaseUtils.uploadPdf(blob, filePath)

    const orderData = {
      company: order.companyName,
      email: order.companyEmail,
      preOrderPdfPath: filePath,
      data: JSON.stringify(order),
      status: OrderStatus.UNREAD,

      sentAt: Date.now(),
      createdBy: user.email,
    }
    const orderDocRef = await addDoc(collection(db, AppConfig.firestore), orderData)

    props.setState(CreateOrderState.SENDING)
    const mail = OrderMail({
      to: order.companyEmail,
      companyName: order.companyName,
      companyManager: order.companyManager,
      orderLink: `${AppConfig.url}${PATHS.ORDER}/${orderDocRef.id}`,
    })

    await FirebaseUtils.sendMail(mail)
    props.setState(CreateOrderState.SENT)
  }

  const css: { [_: string]: React.CSSProperties } = {
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    pdf: {
      backgroundColor: "#fff",
      border: "1px solid #000",
      width: "210mm",
      minHeight: "297mm",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "12px"
    },
    buttonArea: {
      width: "210mm",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "10px",
      display: "flex",
      justifyContent: "space-between"
    },
    button: {
      width: "88px",
      height: "25px",
      border: "1px solid rgb(27, 28, 29)",
      borderRadius: "4px",
      opacity: 1,
      font: "13px / 23px Hiragino Sans",
      color: "rgb(27, 28, 29)",
      backgroundColor: "transparent",
      cursor: "pointer"
    },
    inputAreaCss: {
      marginTop: "20px",
      width: "210mm",
      marginLeft: "auto",
      marginRight: "auto",
    },
    inputWrapperCss: {
      marginTop: "8px",
      display: "flex",
      alignItems: "center"
    },
    inputCss: {
      height: "38px",
      marginLeft: "10px",
      paddingLeft: "10px",
      paddingRight: "10px",
      border: "1px solid #DEDEDF",
      borderRadius: "4px",
      marginTop: "4px"
    },
    lead: {
      textAlign: "center",
      fontSize: "20px",
      marginTop: "140px"
    },
    companyInfo: {
      marginTop: "10px",
      fontSize: "20px"
    }
  }

  return (
    <div>
      <div style={css.buttonArea}>
        <UIButton style={css.button} label={!isPreview ? "確認する" : "再入力"} onClick={() => setIsPreview(!isPreview)}/>
        {isPreview &&
            <React.Fragment>
                <UIButton style={css.button} label={"メール送信"} onClick={printDocument}/>
            </React.Fragment>
        }
      </div>
      {isPreview
        ? <div>
          {AppConfig.debug && <button onClick={() => debugSavePdf()}>save pdf</button>}
          <div style={css.inputAreaCss}>
            <div>送信情報</div>
            <div style={css.companyInfo}>担当者: {order.companyManager}</div>
            <div style={css.companyInfo}>メール: {order.companyEmail}</div>
          </div>
          <div id="divToPrint" style={css.pdf}>
            <MSOSConfirmPreview order={order} orderRightChild={<MSOSCartPreview/>}/>
          </div>
        </div>
        :
        <React.Fragment>
          <div style={css.inputAreaCss}>
            <UITextField required={true} containerCss={css.inputWrapperCss} inputCss={css.inputCss}
                         label={"担当者名　　　"}
                         placeholder={"担当者"}
                         default={order.companyManager}
                         onChange={(value) => order.companyManager = value}/>
            <UITextField required={true} containerCss={css.inputWrapperCss} inputCss={css.inputCss} label={"送付先アドレス"}
                         placeholder={"mail@domain.tld"}
                         default={order.companyEmail}
                         onChange={(value) => order.companyEmail = value}/>
          </div>
          <div style={css.pdf}>
            <MSOSCartInput order={props.order}
                           orderRightChild={<MSOSCartPreview/>}/>
          </div>
        </React.Fragment>
      }
    </div>
  )
}

export default MSOrderSheet