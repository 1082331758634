import html2canvas from "html2canvas";
import JsPDF from "jspdf";

class PdfUtils {
  static async toBlob(elementId: string): Promise<Blob | undefined> {
    const pdf = await this.toPdf(elementId);
    return pdf?.output("blob")
  }

  static async toPdf(elementId: string): Promise<JsPDF | undefined> {
    const html = document.getElementById(elementId)
    if (!html) return
    const canvas = await html2canvas(html)
    const imgData = canvas.toDataURL("image/png")
    const pdf = new JsPDF()
    pdf.addImage(imgData, "JPEG", 0, 0, 210, 297)
    return pdf
  }
}

export default PdfUtils