import React, {Dispatch, SetStateAction} from "react";
import {IOption} from "../../../model/product/option/IOption";
import MSOptionItem from "./MSOptionItem";
import GlobalCss from "../../GlobalCss";
import {RectProps} from "../../../../shared/model/props/RectProps";

type MSOptionProp = {
  option: IOption
  setOrderStr: Dispatch<SetStateAction<string>>
} & RectProps

const MSOption: React.FC<MSOptionProp> = (props) => {

  const css: { [_: string]: React.CSSProperties } = {
    container: {
      width: props.width ?? "100%",
      height: props.height ?? "auto",
      padding: "50px",
      paddingTop: "70px",
      paddingRight: 0
    },
    title: {
      ...GlobalCss.sectionTitle,
    },
    body: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "50px"
    }
  }

  return (
    <div style={css.container}>
      <div style={css.title}>
        OPTION
      </div>
      <div style={css.body}>
        {props.option.items.map((item, index) =>
          <MSOptionItem setOrderStr={props.setOrderStr} key={index} width={"265px"} item={item}/>)}
      </div>
    </div>
  );
}

export default MSOption;