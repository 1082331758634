import PLiew from "./products/PLiew";
import PJukenCompass from "./products/PJukenCompass";
import PShinroShido from "./products/PShinroShido";
import AllProduct from "../main/model/product/AllProduct";

const ProductsData: AllProduct = new AllProduct([
  PJukenCompass,
  PLiew,
  PShinroShido,
])
export default ProductsData;