import CartDetail from "../../model/cart/CartDetail";
import React from "react";
import UILabel from "../../../shared/dom/component/UILabel";
import UIImage from "../../../shared/dom/component/UIImage";
import UICartPrice from "../component/UICartPrice";

type MSCartDetailProp = {
  cart: CartDetail
}

const MSCartDetail: React.FC<MSCartDetailProp> = (props) => {
  const baseStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // borderBottom: "1px solid #E2E2E3",
    paddingTop: "15px",
    paddingBottom: "15px",
    fontSize: "20px",
    fontWeight: 500
  }

  const imgWrap: React.CSSProperties = {
    width: "200px",
    textAlign: "center"
  }

  const text: React.CSSProperties = {
    marginLeft: "40px",
    marginRight: "auto",
    maxWidth: "280px"
  }

  const title: React.CSSProperties = {
    maxWidth: "180px",
    display: "block",
    verticalAlign: "top",
  }

  const description: React.CSSProperties = {
    maxWidth: "375px",
    display: "block",
    verticalAlign: "top",
  }

  const price: React.CSSProperties = {
    fontSize: "24px"
  }

  return (
    <div style={baseStyle}>
      <div style={imgWrap}>
        <UIImage width={"auto"} height={"120px"} src={props.cart.image}/>
      </div>
      <div style={text}>
        <UILabel style={title} label={props.cart.title}/>
        <UILabel style={description} label={props.cart.description}/>
      </div>
      <UICartPrice style={price} purchasable={props.cart.purchasable}/>
    </div>
  )
}

export default MSCartDetail