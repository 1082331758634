import React, {Dispatch, SetStateAction} from "react";
import {TOptionItem} from "../../../model/product/option/IOption";
import SelectableOptionItem from "../../../model/product/option/SelectableOptionItem";
import UICheckBox from "../../../../shared/dom/component/UICheckBox";
import {RectProps} from "../../../../shared/model/props/RectProps";
import UIPurchasablePrice from "../../component/UIPurchasablePrice";
import UISelectPurchasable from "../../component/UISelectPurchasable";
import UIImage from "../../../../shared/dom/component/UIImage";
import UILabel from "../../../../shared/dom/component/UILabel";
import SimpleOptionItem from "../../../model/product/option/SimpleOptionItem";
import SelectionType from "../../../../shared/enum/SelectionType";
import UICountable from "../../component/UICountable";

type UIOptionItemProp = {
  item: TOptionItem
  setOrderStr: Dispatch<SetStateAction<string>>
} & RectProps

const UIOptionItem: React.FC<UIOptionItemProp> = (props) => {
  const css: { [_: string]: React.CSSProperties } = {
    container: {
      width: props.width ?? "100%",
      height: props.height ?? "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      border: "1px solid #e0e0e0",
      paddingTop: "40px",
      paddingBottom: "30px",
      paddingLeft: "30px",
      paddingRight: "30px",
      marginRight: "32px"
    },
    image: {
      width: "110px",
      height: "110px",
    },
    imageWrap: {
      marginTop: "5px"
    },
    purchasablePrice: {
      marginTop: "10px",
    },
    selectPurchasable: {
      marginTop: "10px",
    },
    descWrap: {
      marginTop: "6px"
    },
    checkBoxWrap: {
      fontWeight: "bold"
    }
  }

  const buildPriceLabel = () => {
    switch (props.item.constructor) {
      case SimpleOptionItem:
        const simpleOptionItem: SimpleOptionItem = props.item as SimpleOptionItem
        return (
          <div style={css.purchasablePrice}>
            <UIPurchasablePrice purchasable={simpleOptionItem.purchasable}/>
          </div>)
      case SelectableOptionItem:
        const selectableOptionItem: SelectableOptionItem = props.item as SelectableOptionItem
        return (
          <div style={css.selectPurchasable}>
            <UISelectPurchasable onChange={() => props.setOrderStr(JSON.stringify(props.item))}
                                 item={selectableOptionItem}/>
          </div>)
    }
  }

  const buildCountUp = () => {
    if (!(props.item instanceof SimpleOptionItem))
      return null
    const simpleOptionItem: SimpleOptionItem = props.item as SimpleOptionItem
    if (simpleOptionItem.purchasable.selectionType !== SelectionType.COUNT_UP)
      return null
    return <UICountable onChange={() => props.setOrderStr(JSON.stringify(props.item))}
                        purchasable={simpleOptionItem.purchasable}
                        width={"110px"}/>
  }

  return (
    <div style={css.container}>
      <div style={css.checkBoxWrap}>
        <UICheckBox label={props.item.title} boxPosition={"left"}
                    checked={props.item.isInCart}
                    onChange={checked => {
                      props.item.isInCart = checked
                      props.setOrderStr(JSON.stringify(props.item))
                    }}/>
      </div>
      <div style={css.imageWrap}>
        <UIImage width={css.image.width} height={css.image.height} src={props.item.imageUrl}/>
      </div>
      {buildPriceLabel()}
      <div style={css.descWrap}>
        <UILabel label={props.item.description}/>
      </div>
      {buildCountUp()}
    </div>
  );
}

export default UIOptionItem;