import React from "react";
import ISummary from "../../model/product/summary/ISummary";
import UIImage from "../../../shared/dom/component/UIImage";
import UILabel from "../../../shared/dom/component/UILabel";

type MSProductHeaderProp = {
  summary: ISummary
}

const MSProductHeader: React.FC<MSProductHeaderProp> = (props) => {

  const productHeaderCss: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    paddingTop: "50px",
    paddingLeft: "50px",
    paddingRight: "50px",
  }
  const productHeaderRightCss: React.CSSProperties = {
    paddingLeft: "24px",
  }
  const productHeaderRightTitleCss: React.CSSProperties = {
    fontWeight: "bold"
  }
  const imageStyle: React.CSSProperties = {
    width: "150px",
    height: "106px"
  }

  return (
    <React.Fragment>
      <div style={productHeaderCss}>
        <UIImage width={imageStyle.width} height={imageStyle.height} src={props.summary.imageUrl}/>
        <div style={productHeaderRightCss}>
          <UILabel style={productHeaderRightTitleCss} label={props.summary.title}/>
          <div>
            <a href={"#top"}>一覧はこちら</a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MSProductHeader;