import React, {Dispatch, SetStateAction} from "react";
import {TPlanItem} from "../../../model/product/plan/IPlan";
import AdditionalPlanItem from "../../../model/product/plan/AdditionalPlanItem";
import UIImage from "../../../../shared/dom/component/UIImage";
import UILabel from "../../../../shared/dom/component/UILabel";
import UIPurchasablePrice from "../../component/UIPurchasablePrice";
import UICountable from "../../component/UICountable";
import {RectProps} from "../../../../shared/model/props/RectProps";
import ProductUtils from "../../../../shared/utils/ProductUtils";

type MSPlanItemProp = {
  item: TPlanItem,
  onClickPlan: (_: TPlanItem) => void
  setOrderStr: Dispatch<SetStateAction<string>>
} & RectProps

const MSPlanItem: React.FC<MSPlanItemProp> = (props) => {
  const css: { [_: string]: React.CSSProperties } = {
    container: {
      width: props.width ?? "100%",
      height: props.height ?? "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#fff",
      borderRadius: "0px 50px 10px 10px",
      boxShadow: "0px 3px 6px #00000029",
      paddingTop: "32px",
      paddingBottom: "32px",
      cursor: "pointer"
    },
    image: {
      width: "96px",
      height: "96px",
    },
    labelContainer: {
      marginTop: "10px",
      fontWeight: "bold"
    },
    purchasePriceContainer: {
      marginTop: "10px"
    },
    list: {
      paddingLeft: "12px",
      fontSize: "14px",
      marginTop: "5px",
      marginBottom: 0
    },
    optionContainer: {
      textAlign: "center",
      marginTop: "18px"
    },
    optionText: {
      fontSize: "14px",
      color: "#00000066",
      fontWeight: 600
    }
  }

  const buildOptions = () => {
    if (props.item instanceof AdditionalPlanItem) {
      const additionalItem: AdditionalPlanItem = props.item as AdditionalPlanItem
      const description = `${ProductUtils.formatPrice(additionalItem.additionalPurchasable.price, true)} / ${additionalItem.additionalPurchasable.name}`
      return (
        <div style={css.optionContainer}>
          <div style={css.optionText}>追加</div>
          <UICountable width={"100px"} purchasable={props.item.additionalPurchasable}
                       onChange={() => props.setOrderStr(JSON.stringify(props.item))} description={description}/>
        </div>
      )
    }
  }

  return (
    <div style={css.container} onClick={() => props.onClickPlan(props.item)}>
      <UIImage width={css.image.width} height={css.image.height} src={props.item.imageUrl}/>
      <div style={css.labelContainer}>
        <UILabel tag={"div"} label={props.item.title}/>
      </div>
      <div style={css.purchasePriceContainer}>
        <UIPurchasablePrice purchasable={props.item.purchasable}/>
      </div>
      <ul style={css.list}>
        {props.item.notes.map((note, index) =>
          <UILabel key={index} tag={'li'} label={note}/>)}
      </ul>
      {buildOptions()}
    </div>
  );
}

export default MSPlanItem;