import PaymentType from "../enum/PaymentType";

class PaymentPrice {
  paymentType: PaymentType
  total: number

  constructor(paymentType: PaymentType, total: number) {
    this.paymentType = paymentType;
    this.total = total;
  }
}

class TotalPrice {
  prices: PaymentPrice[] = []

  get total(): number {
    return this.sum(this.prices)
  }

  constructor(prices: PaymentPrice[] = []) {
    this.prices = prices
  }

  static get empty(): TotalPrice {
    return new TotalPrice()
  }

  static build(paymentType: PaymentType, price: number): TotalPrice {
    return new TotalPrice([new PaymentPrice(paymentType, price)])
  }

  add(totalPrice: TotalPrice): TotalPrice {
    return totalPrice.prices.reduce((prev: TotalPrice, curPrice) => prev.addPaymentPrice(curPrice), this)
  }

  getSumOf(paymentType: PaymentType): number {
    return this.sum(this.prices.filter(p => p.paymentType === paymentType))
  }

  private addPaymentPrice(price: PaymentPrice): TotalPrice {
    if (this.prices.every(p => p.paymentType !== price.paymentType)) {
      return new TotalPrice([...this.prices, price])
    } else {
      const newPrices = this.prices.map(p => {
        if (p.paymentType === price.paymentType) {
          p.total += price.total
        }
        return p
      })
      return new TotalPrice(newPrices)
    }
  }

  private sum(prices: PaymentPrice[]): number {
    return prices.map(_ => _.total).reduce((prevPrice, curPrice) => prevPrice + curPrice, 0)
  }
}

export default TotalPrice