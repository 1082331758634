import React, {CSSProperties, FC, useState} from 'react';
import AppBody from "../../shared/dom/AppBody";
import MSProduct from "./product/MSProduct";
import AppHeader from "../../shared/dom/AppHeader";
import AppFooter from "../../shared/dom/AppFooter";
import AppHeaderButton from "../../shared/dom/AppHeaderButton";
import MSProductSummary from "./product/MSProductSummary";
import {PATHS} from "../../index";
import AllProduct from "../model/product/AllProduct";
import {AppConfig} from "../../shared/config/AppConfig";
import MSCartSummary from "./product/MSCartSummary";

type MSProductsProp = {
  data: AllProduct
}

const MSPageProducts: FC<MSProductsProp> = (props) => {
  let [, setOrderStr] = useState(JSON.stringify(props.data))

  const css: { [_: string]: CSSProperties } = {
    section: {
      width: "920px",
      maxWidth: "100%",
      paddingLeft: "50px",
      paddingRight: "50px",
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop: "50px",
      paddingBottom: "50px"
    },
    title: {
      textAlign: "center",
      font: "normal normal bold 30px/30px Lato",
      letterSpacing: "2.4px",
      color: "#000000DE",
      opacity: 1,
    },
    content: {
      width: "100%",
      height: "auto",
      display: "flex",
      justifyContent: "space-between",
      marginTop: "50px"
    },
  }

  return (
    <React.Fragment>
      <AppHeader>
        <AppHeaderButton to={PATHS.CART} label={"カートへ"} allProduct={props.data}/>
        {AppConfig.debug && <button onClick={() => console.log(props.data)}> print object</button>}
      </AppHeader>
      <AppBody>
        <div style={css.section}>
          <a id={"top"}
             style={{display: "block", position: "relative", top: "-100px", visibility: "hidden"}}></a>
          <div style={css.title}>PRODUCT</div>
          <MSCartSummary data={props.data}/>
          <div style={css.content}>
            {props.data.products.map((product, index) =>
              <MSProductSummary
                key={index}
                width={"240px"}
                height={"auto"}
                padding={"0"}
                summary={product.summary}/>)}
          </div>
        </div>
        <div>
          {props.data.products.map((product, index) =>
            <MSProduct key={index} product={product} setOrderStr={setOrderStr}/>)}
        </div>
      </AppBody>
      <AppFooter/>
    </React.Fragment>
  )
}

export default MSPageProducts
