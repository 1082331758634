import {useAuthContext} from "../config/GoogleAuthentication";
import React, {FC, Fragment, ReactNode} from 'react';
import GoogleSignIn from "../dom/GoogleSignIn";

type PrivateRouteProps = {
  children: ReactNode
}

const PrivateRoute: FC<PrivateRouteProps> = (props) => {
  const user = useAuthContext()
  return (
    <Fragment>
      {user ? props.children : <GoogleSignIn/>}
    </Fragment>
  )
}

export default PrivateRoute