import React, {FC} from "react";
import PaymentType from "../../../shared/enum/PaymentType";
import {Order, OrderDetail} from "../../model/order/Order";
import ProductUtils from "../../../shared/utils/ProductUtils";

type MSDetailSumProp = {
  order: Order
}

export const MSDetailSumCss: { [_: string]: React.CSSProperties } = {
  table: {
    width: "314px",
    height: "140px",
    border: "2px solid #707070",
    borderCollapse: "collapse"
  },
  th: {
    textAlign: "center",
    width: "110px",
    backgroundColor: "#404040",
    color: "#fff",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "1.2em",
    borderBottom: "1px solid #fff"
  },
  thSpan: {
    fontSize: "12px",
    display: "block"
  },
  td: {
    textAlign: "right",
    paddingRight: "10px",
    fontSize: "24px",
    borderBottom: "1px solid #707070"
  },
  textTd: {
    textAlign: "right",
    paddingRight: "10px",
    fontSize: "15px",
    fontWeight: "bold",
    borderBottom: "1px solid #707070"
  }
}

const MSDetailSum: FC<MSDetailSumProp> = (props) => {

  const buildLabel = (paymentType: PaymentType) => {
    switch (paymentType) {
      case PaymentType.BUYOUT:
        return <td style={MSDetailSumCss.th}>初期費用<span style={MSDetailSumCss.thSpan}>（消費税込）</span></td>
      case PaymentType.SUBSCRIPTION:
        return <td style={MSDetailSumCss.th}>月額費用<span style={MSDetailSumCss.thSpan}>（消費税込）</span></td>
    }
  }

  return (
    <div>
      <table style={MSDetailSumCss.table}>
        <tbody>
        {props.order.details.map(detailObj => {
          const detail = OrderDetail.decode(detailObj)
          return (
            <tr key={detail.id}>
              {buildLabel(detail.paymentType)}
              <td style={MSDetailSumCss.td}>
                ￥{ProductUtils.formatPriceWithTax(detail.total)}-
              </td>
            </tr>
          )
        })}
        <tr>
          <th style={MSDetailSumCss.th}>見積有効期限</th>
          <td style={MSDetailSumCss.textTd}>
            <div>{props.order.orderSpan}</div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  )
}

export default MSDetailSum
