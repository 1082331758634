import Product from "../../main/model/product/Product";
import Summary from "../../main/model/product/summary/Summary";
import Initial from "../../main/model/product/initial/Initial";
import SingleInitialItem from "../../main/model/product/initial/SingleInitialItem";
import SimplePlan from "../../main/model/product/plan/SimplePlan";
import Option from "../../main/model/product/option/Option";
import AdditionalPlanItem from "../../main/model/product/plan/AdditionalPlanItem";
import SelectionType from "../../shared/enum/SelectionType";
import PaymentType from "../../shared/enum/PaymentType";
import SimpleOptionItem from "../../main/model/product/option/SimpleOptionItem";

const imagePath = "/products/shinroshido"

const summary = new Summary(
  "進路指導.net",
  "https://www.lacicu.co.jp/wp/wp-content/themes/standard/images/service/service_lineup-shinroshidou.jpg",
  "進路指導.netは、高校部運営に当たって重要な大学情報を網羅した“大学情報サイト”の販売サービスです。")

const initial = new Initial([
  new SingleInitialItem(
    "初期設定",
    "",
    `${imagePath}/init01.jpg`,
    150000),
])

const planMemo = ` ■ システム（進路指導.net）の最低契約期間は月額発生月より1年間となっております。
 ■ 進路指導.netの月額費用は、生徒数・教室数によって変わります。
 ■ 初期費用のお支払いは、ご注文いただいた月の翌月となります。`

const plans = new SimplePlan([
  new AdditionalPlanItem("ライトプラン", `${imagePath}/planS.jpg`, ["1教室", "各教室150名まで"], 30000, "1教室", 5000, planMemo),
  new AdditionalPlanItem("レギュラープラン", `${imagePath}/planM.jpg`, ["10教室", "各教室300名まで"], 50000, "1教室", 2000, planMemo),
  new AdditionalPlanItem("ビッグプラン", `${imagePath}/planL.jpg`, ["30教室", "各教室1000名まで"], 70000, "1教室", 1000, planMemo),
])

const options = new Option([
  new SimpleOptionItem("全国共通テスト連携", "", `${imagePath}/op01.jpg`, PaymentType.SUBSCRIPTION, 30000, SelectionType.SINGLE),
  new SimpleOptionItem("アプリ化", "", `${imagePath}/op02.jpg`, PaymentType.BUYOUT, 200000, SelectionType.SINGLE),
])

const PShinroShido: Product = new Product(summary, initial, plans, options);
export default PShinroShido;