import React, {CSSProperties, Dispatch, SetStateAction} from "react";
import StyleProps from "../../../shared/model/props/StyleProps";
import IProduct from "../../model/product/IProduct";
import MSInitial from "./initial/MSInitial";
import MSPlan from "./plan/MSPlan";
import MSOption from "./option/MSOption";
import MSProductHeader from "./MSProductHeader";

type MSProductProp = {
  product: IProduct
  setOrderStr: Dispatch<SetStateAction<string>>
} & StyleProps

const MSProduct: React.FC<MSProductProp> = (props) => {
  const productContainer: CSSProperties = {
    width: "920px",
    maxWidth: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    borderTop: "2px solid #e0e0e0"
  }
  return (
    <React.Fragment>
      <div style={productContainer}>
        <a id={props.product.summary.title}
           style={{display: "block", position: "relative", top: "-60px", visibility: "hidden"}}></a>
        <MSProductHeader summary={props.product.summary}/>
        <MSInitial initial={props.product.initial} setOrderStr={props.setOrderStr}/>
        <MSPlan plan={props.product.plan} setOrderStr={props.setOrderStr}/>
        <MSOption option={props.product.option} setOrderStr={props.setOrderStr}/>
      </div>
    </React.Fragment>
  );
}

export default MSProduct;