import PaymentType from "../../../../shared/enum/PaymentType";
import SimpleMerchandise from "../../../../shared/model/merchandise/SimpleMerchandise";
import SelectionType from "../../../../shared/enum/SelectionType";
import {IPlanItem} from "./IPlan";

class SimplePlanItem extends SimpleMerchandise implements IPlanItem {
  isInCart: boolean = false
  memo: string

  constructor(title: string, imageUrl: string, notes: string[], price: number, memo: string) {
    super(title, "", imageUrl, notes, "", PaymentType.SUBSCRIPTION, price, SelectionType.SINGLE);
    this.memo = memo
  }
}

export default SimplePlanItem