import React, {FC, ReactNode} from 'react';
import {Order} from "../../model/order/Order";
import UIImage from "../../../shared/dom/component/UIImage";
import MSDetailSum from "../confirm/MSDetailSum";
import {MSTable} from "../confirm/MSTable";

type MSOSConfirmPreviewProps = {
  order?: Order
  orderRightChild: ReactNode
}

const MSOSConfirmPreview: FC<MSOSConfirmPreviewProps> = (props) => {
  const css: { [_: string]: React.CSSProperties } = {
    pdf: {
      paddingTop: "35px",
      paddingBottom: "40px",
      paddingLeft: "25px",
      paddingRight: "25px"
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "end",
      paddingLeft: "23px",
      paddingRight: "23px",
      paddingBottom: "25px",
      borderBottom: "2px solid #e0e0e0"
    },
    headerTitle: {
      font: "normal bold normal 24px/24px Hiragino Sans",
      letterSpacing: "1.2px",
      color: "#000000DE",
      opacity: 1,
    },
    quote: {
      width: "100%",
      paddingLeft: "23px",
      paddingRight: "23px",
      paddingTop: "25px",
      paddingBottom: "40px"
    },
    quoteCol: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    quoteLeft: {
      width: "60%",
      paddingRight: "67px"
    },
    quoteRight: {
      width: "40%",
      position: "relative"
    },
    quoteRightInfo: {
      position: "relative",
      zIndex: 2
    },
    signature: {
      position: "absolute",
      top: "47px",
      zIndex: 1,
      right: 0
    },
    companyNameArea: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "24px"
    },
    companyName: {
      width: "260px"
    },
    address: {
      marginTop: "20px"
    },
    detailSum: {
      marginTop: "20px"
    },
    orderCol: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    orderLeft: {
      width: "40%",
      fontSize: "12px"
    },
    orderLeft1: {
      borderBottom: "2px solid #000",
      width: "160px",
      marginBottom: "3px"
    },
    orderRight: {
      width: "60%",
    },
    textareaTitle: {
      fontWeight: 600,
      paddingTop: "30px",
      paddingBottom: "5px",
    },
    textareaText: {
      whiteSpace: "pre-wrap",
    },
    order: {
      paddingTop: "20px",
      paddingLeft: "23px",
      paddingRight: "23px",
    },
    orderDateArea: {
      marginTop: "60px"
    },
    startFrom: {
      width: "160px",
      borderBottom: "1px solid #000",
    },
    payingFrom: {
      width: "160px",
      borderBottom: "1px solid #000",
      marginTop: "40px"
    }
  }

  return (
    <React.Fragment>
      {props.order &&
        <div style={css.pdf}>
          <div>
            {/*header*/}
            <div style={css.header}>
              <div style={css.headerTitle}>御見積書</div>
              <UIImage height={"29px"} width={"auto"} src={"/logo-landscape.png"}></UIImage>
            </div>
            {/*body*/}
            <div style={css.quote}>
              <div style={css.quoteCol}>
                <div style={css.quoteLeft}>
                  <div style={css.companyNameArea}>
                    <div style={css.companyName}>{props.order.companyName}</div>
                    <div>御中</div>
                  </div>
                  <div style={css.detailSum}>
                    <MSDetailSum order={props.order}/>
                  </div>
                </div>
                <div style={css.quoteRight}>
                  <div>{props.order.orderAt}</div>
                  <div style={css.quoteRightInfo}>
                    <div style={css.address}>〒101-0031</div>
                    <div>東京都千代田区東神田1-2-10</div>
                    <div>泰岳ビル 5階</div>
                    <div>株式会社ラシク</div>
                    <div>TEL: 03-5829-6992</div>
                    <div>担当者: {props.order.managerName}</div>
                    <div>email: {props.order.managerEmail}</div>
                  </div>
                  <div style={css.signature}>
                    <UIImage width={"100px"} src={"/signature/signature.png"}></UIImage>
                  </div>
                </div>
              </div>
              {props.order.details.map(detail => <MSTable key={detail.id} detail={detail}/>)}
              <div>
                <div style={css.textareaTitle}>
                  備考
                </div>
                <div style={css.textareaText}>{props.order.memo}</div>
              </div>
            </div>
          </div>
          <div>
            {/*header*/}
            <div style={css.header}>
              <div style={css.headerTitle}>注文書</div>
            </div>
            {/*body*/}
            <div style={css.order}>
              <div style={css.orderCol}>
                <div style={css.orderLeft}>
                  <div style={css.orderLeft1}>株式会社Lacicu 宛</div>
                  <div>上記の通り注文いたします</div>
                  <div style={css.orderDateArea}>
                    <div style={css.startFrom}>ご利用開始月 : {props.order.startFrom}</div>
                    <div style={css.payingFrom}>月額費用発生月 : {props.order.payingFrom}</div>
                  </div>
                </div>
                <div style={css.orderRight}>
                  {props.orderRightChild}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  )
}
export default MSOSConfirmPreview
