import React, {Dispatch, SetStateAction} from "react";
import UICheckBox from "../../../../shared/dom/component/UICheckBox";
import MSInitialItem from "./MSInitialItem";
import {IInitial} from "../../../model/product/initial/IInitial";
import {RectProps} from "../../../../shared/model/props/RectProps";

type MSInitialProp = {
  initial: IInitial
  setOrderStr: Dispatch<SetStateAction<string>>
} & RectProps

const MSInitial: React.FC<MSInitialProp> = (props) => {
  const css: { [_: string]: React.CSSProperties } = {
    container: {
      width: props.width ?? "100%",
      height: props.height ?? "auto",
      padding: "50px"
    },
    body: {
      display: "flex",
    },
    checkBoxContainer: {
      width: "50%",
      paddingTop: "90px",
      paddingLeft: "110px"
    },
    initialItemContainer: {
      width: "50%",
      display: "flex",
      flexWrap: "wrap"
    }
  }

  return (
    <div style={css.container}>
      <div style={css.body}>
        <div style={css.checkBoxContainer}>
          <UICheckBox boxPosition={"left"} label={"導入費用"}
                      checked={props.initial.isInCart}
                      onChange={checked => {
                        props.initial.isInCart = checked
                        props.setOrderStr(JSON.stringify(props.initial))
                      }}></UICheckBox>
        </div>
        <div style={css.initialItemContainer}>
          {props.initial.items.map((item, index) =>
            <MSInitialItem key={index} width={"203px"} item={item}/>)}
        </div>
      </div>
    </div>
  )
}

export default MSInitial;