import React, {useId} from "react";
import IPurchasable from "../../../shared/model/purchasable/IPurchasable";
import UIPurchasablePrice from "./UIPurchasablePrice";
import SelectableOptionItem from "../../model/product/option/SelectableOptionItem";
import UILabel from "../../../shared/dom/component/UILabel";

type UISelectPurchasableProp = {
  item: SelectableOptionItem
  onChange?: () => void
}

const UISelectPurchasable: React.FC<UISelectPurchasableProp> = (props) => {
  const css: { [_: string]: React.CSSProperties } = {
    label: {
      display: "inline-block",
      width: "60px"
    }
  }

  const radioUid = useId();

  const SelectOption = (purchasable: IPurchasable) => {
    const uid = useId();
    return (
      <React.Fragment key={uid}>
        <div>
          <label htmlFor={uid}>
            <input name={radioUid} type="radio" id={uid}
                   onChange={() => {
                     onChecked(purchasable)
                     props.onChange?.()
                   }}/>
            <UILabel style={css.label} label={purchasable.name}/>
            <UIPurchasablePrice purchasable={purchasable}/>
          </label>
        </div>
      </React.Fragment>
    )
  }

  const onChecked = (target: IPurchasable) => {
    props.item.selectedIndex = props.item.purchasables.indexOf(target)
    props.item.isSelected = (props.item.selectedIndex > -1)
  }

  return (
    <div>
      {props.item.purchasables.map(p => SelectOption(p))}
    </div>
  );
}

export default UISelectPurchasable;