import PaymentType from "../../../../shared/enum/PaymentType";
import TotalPrice from "../../../../shared/model/TotalPrice";
import SimpleMerchandise from "../../../../shared/model/merchandise/SimpleMerchandise";
import SelectionType from "../../../../shared/enum/SelectionType";
import SimplePurchasable from "../../../../shared/model/purchasable/SimplePurchasable";
import {OrderItem} from "../../order/Order";
import CartDetail from "../../cart/CartDetail";
import {IPlanItem} from "./IPlan";

class AdditionalPlanItem extends SimpleMerchandise implements IPlanItem {
  isInCart: boolean = false
  memo: string

  additionalPurchasable: SimplePurchasable

  constructor(title: string, imageUrl: string, notes: string[], price: number, exName: string, exPrice: number, memo: string) {
    super(title, "", imageUrl, notes, "", PaymentType.SUBSCRIPTION, price, SelectionType.SINGLE)
    this.additionalPurchasable = new SimplePurchasable(exName, PaymentType.SUBSCRIPTION, exPrice, SelectionType.COUNT_UP)
    this.additionalPurchasable.quantity = 0
    this.memo = memo
  }

  override calcPrice(): TotalPrice {
    return this.purchasable.calcPrice().add(this.additionalPurchasable.calcPrice())
  }

  override toCardDetail(): CartDetail[] {
    const baseCartItem = new CartDetail(this.title, this.imageUrl, this.description, this.purchasable)
    if (this.additionalPurchasable.quantity === 0) {
      return [baseCartItem]
    } else {
      return [baseCartItem,
        new CartDetail(`${this.title} 追加 ID`, this.imageUrl, this.description, this.additionalPurchasable)]
    }
  }

  override toOrderItem(paymentType: PaymentType, ...args: string[]): OrderItem[] {
    return [...super.toOrderItem(paymentType, ...args),
      ...this.additionalPurchasable.toOrderItem(paymentType, ...args, this.title, "追加")]
  }
}

export default AdditionalPlanItem