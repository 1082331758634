import React, {useState} from "react";
import UIButton from "../../../shared/dom/component/UIButton";
import UILabel from "../../../shared/dom/component/UILabel";
import {RectProps} from "../../../shared/model/props/RectProps";
import IPurchasable from "../../../shared/model/purchasable/IPurchasable";

type UICountableProp = {
  purchasable: IPurchasable
  description?: string,
  onChange?: () => void
} & RectProps

const UICountable: React.FC<UICountableProp> = (props) => {
  let [count, setCount] = useState(props.purchasable.quantity);

  function updateCount(value: number): void {
    if (value < 0) {
      props.purchasable.quantity = 0
      props.onChange?.()
      setCount(0)
      return;
    }
    props.onChange?.()
    props.purchasable.quantity = value
    setCount(value)
  }

  const css: { [_: string]: React.CSSProperties } = {
    container: {
      width: props.width ?? "100%",
      height: props.height ?? "100%",
      display: "flex",
      justifyContent: "space-between",
      marginTop: "8px"
    },
    label: {
      color: "#00000066",
      fontSize: "14px",
      fontWeight:600
    },
    button: {
      width: "20px",
      height: " 20px",
      textAlign: "center",
      lineHeight: "20px",
      color: "#fff",
      fontSize: "20px",
      backgroundColor: "#90CDF0",
      borderRadius: "50%",
      border: "none",
      fontWeight: "bold",
      padding: 0
    },
    countLabel: {
      fontWeight: 600
    }
  }

  return (
    <div>
      {!!props.description && <UILabel style={css.label} label={props.description}/>}
      <div style={css.container}>
        <UIButton style={css.button} label={"-"} onClick={() => updateCount(count - 1)}/>
        <UILabel style={css.countLabel} label={count}/>
        <UIButton style={css.button} label={"+"} onClick={() => updateCount(count + 1)}/>
      </div>
    </div>
  );
}

export default UICountable;