import PaymentType from "../../../../shared/enum/PaymentType";
import SimpleMerchandise from "../../../../shared/model/merchandise/SimpleMerchandise";
import SelectionType from "../../../../shared/enum/SelectionType";
import {IPlanItem} from "./IPlan";

class ComparablePlanItem extends SimpleMerchandise implements IPlanItem {
  isInCart: boolean = false
  compare: string
  memo: string

  constructor(title: string, imageUrl: string, notes: string[], price: number, compare: string, memo: string) {
    super(title, "", imageUrl, notes, "", PaymentType.SUBSCRIPTION, price, SelectionType.SINGLE);
    this.compare = compare
    this.memo = memo
  }
}

export default ComparablePlanItem