import {IInitial, TInitialItem} from "./IInitial";
import TotalPrice from "../../../../shared/model/TotalPrice";
import PaymentType from "../../../../shared/enum/PaymentType";
import {OrderItem} from "../../order/Order";

class Initial implements IInitial {
  isInCart: boolean = false
  items: TInitialItem[]

  constructor(items: TInitialItem[]) {
    this.items = items
  }

  calcPrice(): TotalPrice {
    if (!this.isInCart) return TotalPrice.empty
    return this.items.reduce((prev: TotalPrice, cur) => prev.add(cur.calcPrice()), TotalPrice.empty)
  }

  toOrderItem(paymentType: PaymentType, ...args: string[]): OrderItem[] {
    if (!this.isInCart) return []
    return this.items.flatMap(_ => _.toOrderItem(paymentType, ...args))
  }
}

export default Initial
