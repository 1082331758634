import React, {FC, ReactNode, useLayoutEffect} from 'react';
import {useLocation,} from 'react-router-dom';

type ScrollToTopProps = {
  children: ReactNode
}

const ScrollToTop: FC<ScrollToTopProps> = (props) => {
  const location = useLocation()
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname])

  return (
    <>
      {props.children}
    </>
  )
}

export default ScrollToTop