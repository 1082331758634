import React, {CSSProperties, useState} from 'react';
import {PATHS} from "../../index";
import AppHeader from "../../shared/dom/AppHeader";
import AppHeaderButton from "../../shared/dom/AppHeaderButton";
import {OrderDetail, OrderInput} from "../model/order/Order";
import PaymentType from "../../shared/enum/PaymentType";
import {Link, useLocation} from "react-router-dom";
import AllProduct from "../model/product/AllProduct";
import AppFooter from "../../shared/dom/AppFooter";
import AppBody from "../../shared/dom/AppBody";
import MSOrderSheet from "./order/MSOrderSheet";
import UILoading from "../../shared/dom/UILoading";

enum CreateOrderState {
  PREPARE,
  PRINTING,
  SAVING,
  SENDING,
  SENT,
}

const MSPageCreateOrder = () => {
  let valid = false
  const location = useLocation()

  let data = undefined
  let order = new OrderInput([], "")
  if (location.state && location.state instanceof AllProduct) {
    valid = true
    data = location.state as AllProduct
    order = new OrderInput(
      [
        new OrderDetail(PaymentType.BUYOUT, data.toOrderItem(PaymentType.BUYOUT)),
        new OrderDetail(PaymentType.SUBSCRIPTION, data.toOrderItem(PaymentType.SUBSCRIPTION))
      ], data.buildMemo())
  }

  let [state, setState] = useState(CreateOrderState.PREPARE)

  const css: { [_: string]: CSSProperties } = {
    buttonWrapper: {
      marginRight: "20px"
    },
    title: {
      textAlign: "center",
      fontSize: "30px"
    },
    bodyInner: {
      paddingTop: "30px",
      paddingBottom: "60px"
    },
    lead: {
      textAlign: "center",
      fontSize: "20px",
      marginTop: "140px"
    },
    topButton: {
      textAlign: "center",
      fontSize: "24px"
    },
  }
  return (
    <React.Fragment>
      {valid ? <React.Fragment>
          {state === CreateOrderState.PREPARE &&
              <React.Fragment>
                  <AppHeader>
                      <div style={css.buttonWrapper}>
                          <AppHeaderButton to={PATHS.CART} label={"戻る"} allProduct={data}/>
                      </div>
                  </AppHeader>
                  <AppBody>
                      <div style={css.bodyInner}>
                          <div style={css.title}>
                              見積書作成
                          </div>
                          <MSOrderSheet order={order} setState={setState}/>
                      </div>
                  </AppBody>
                  <AppFooter/>
              </React.Fragment>
          }

          {[CreateOrderState.PRINTING, CreateOrderState.SAVING, CreateOrderState.SENDING, CreateOrderState.SENT].includes(state) &&
              <div>
                  <div style={css.title}>
                    {state === CreateOrderState.PRINTING && "出力中..."}
                    {state === CreateOrderState.SAVING && "保存中..."}
                    {state === CreateOrderState.SENDING && "送信中..."}
                    {state === CreateOrderState.SENT && "送信"}
                      <UILoading
                          loading={[CreateOrderState.PRINTING, CreateOrderState.SAVING, CreateOrderState.SENDING].includes(state)}/>
                      <div style={css.lead}>
                        {state === CreateOrderState.PRINTING && "注文書を作成しています。"}
                        {state === CreateOrderState.SAVING && "情報を保存しています。"}
                        {state === CreateOrderState.SENDING && "メールを送信しています。"}
                        {state === CreateOrderState.SENT && "メールを送信しました。"}
                      </div>
                    {state === CreateOrderState.SENT &&
                        <Link style={css.priceTitle} to={PATHS.PRODUCTS}>
                            トップに戻る
                        </Link>
                    }
                  </div>
              </div>
          }
        </React.Fragment>
        : <Link style={css.topButton} to={PATHS.PRODUCTS}>
          トップに戻る
        </Link>
      }
    </React.Fragment>
  )
}

export {CreateOrderState}
export default MSPageCreateOrder
