import React, {CSSProperties, FC} from "react";
import UIUnit from "./UIUnit";
import StyleProps from "../../model/props/StyleProps";

type UILabelProp = {
  tag?: string
  label: React.ReactNode
} & StyleProps

const UILabel: FC<UILabelProp> = (props) => {

  const css: CSSProperties = {
    whiteSpace: "pre-wrap",
    ...props.style,
  }

  return (
    <React.Fragment>
      <UIUnit tag={props.tag ?? "span"} style={css}>
        {props.label}
      </UIUnit>
    </React.Fragment>
  );
}

export default UILabel;