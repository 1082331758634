import React, {CSSProperties} from "react";
import UILabel from "../../../shared/dom/component/UILabel";
import UIPrice from "../component/UIPrice";

type MSCartPriceProp = {
  title: string
  price: number
}

const MSCartPrice: React.FC<MSCartPriceProp> = (props) => {

  const css: { [_: string]: CSSProperties } = {
    baseStyle: {
      width: "610px",
      marginRight: "auto",
      marginLeft: "auto",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      borderBottom: "2px solid #E1E1E1",
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    title: {
      fontSize: "22px"
    },
    price: {
      fontSize: "22px"
    }
  }
  return (
    <div style={css.baseStyle}>
      <UILabel style={css.title} label={props.title}/>
      <UIPrice style={css.price} price={props.price} suffix={"（税抜）"}/>
    </div>
  )
}

export default MSCartPrice