import React, {FC, useEffect, useState} from 'react';
import {collection, getDocs} from "firebase/firestore";
import {db, storage} from "../../../shared/config/FirebaseApp";
import {AppConfig} from "../../../shared/config/AppConfig";
import {OrderStatus} from "../../../shared/utils/FirebaseUtils";
import DateUtils from "../../../shared/utils/DateUtils";
import {getDownloadURL, ref} from "firebase/storage";

const MSOrderList = () => {

  class OrderSummary {
    company: string
    email: string
    data: string

    _sentAt: Date
    get sentAt(): string {
      return DateUtils.toFormatted(this._sentAt)
    }

    preOrderPdfPath: string

    _confirmedAt?: Date
    get confirmedAt(): string {
      return this._confirmedAt ? DateUtils.toFormatted(this._confirmedAt) : ""
    }

    orderPdfPath?: string

    status: OrderStatus
    createdBy: string

    constructor(data: any) {
      this.company = data.company
      this.email = data.email
      this.data = data.data

      this.preOrderPdfPath = data.preOrderPdfPath
      this._sentAt = new Date(Number(data.sentAt))

      this.orderPdfPath = data.orderPdfPath ? data.orderPdfPath : undefined
      this._confirmedAt = data.confirmedAt ? new Date(Number(data.confirmedAt)) : undefined

      this.status = data.status
      this.createdBy = data.createdBy
    }
  }

  enum State {
    LOADING,
    LOADED,
    FAILED
  }

  let [orders, setOrders] = useState<OrderSummary[]>([])
  useEffect(() => {
    getDocs(collection(db, AppConfig.firestore)).then(snapshots => {
      const orders = snapshots.docs.map(doc => new OrderSummary(doc.data()))
      setOrders([...orders])
    })
  }, [])

  type OrdersTableProps = {
    orders: OrderSummary[]
  }
  const OrdersTable: FC<OrdersTableProps> = ({orders}) => {
    return (<table>
      <thead>
      <tr>
        <th>会社名</th>
        <th>連絡先</th>
        <th>ステータス</th>

        <th>注文書</th>
        <th>送信日付</th>

        <th>確定注文書</th>
        <th>注文日付</th>

        <th>担当者</th>
      </tr>
      </thead>
      <tbody>
      {orders.map((order, index) =>
        <OrderRow order={order} key={index}/>)}
      </tbody>
    </table>)
  }

  type OrderRowProps = {
    order: OrderSummary
  }
  const OrderRow: FC<OrderRowProps> = ({order}) => {
    function toStatus(status: OrderStatus) {
      switch (status) {
        case OrderStatus.UNREAD:
          return "未読"
        case OrderStatus.READ:
          return "開封済み"
        case OrderStatus.CONFIRMED:
          return "注文済み"
      }
    }

    let [preOrderUrl, setPreOrderUrl] = useState(order.preOrderPdfPath)
    let [orderUrl, setOrderUrl] = useState(order.orderPdfPath ?? "")

    useEffect(() => {
      console.error(order)
      getDownloadURL(ref(storage, order.preOrderPdfPath)).then((url) =>
        setPreOrderUrl(url)
      )

      if (order.orderPdfPath) {
        getDownloadURL(ref(storage, order.orderPdfPath)).then(async (url) =>
          setOrderUrl(url)
        )
      }
    }, [])

    return (
      <tr>
        <td>{order.company}</td>
        <td>{order.email}</td>
        <td>{toStatus(order.status)}</td>

        <td>{preOrderUrl && <a href={preOrderUrl} target={"_blank"}>注文書</a>}</td>
        <td>{order.sentAt}</td>

        <td>{orderUrl && <a href={orderUrl} target={"_blank"}>確定注文書</a>}</td>
        <td>{order.confirmedAt}</td>

        <td>{order.createdBy}</td>
      </tr>)
  }

  return (
    <React.Fragment>
      <OrdersTable orders={orders}/>
    </React.Fragment>
  )
}

export default MSOrderList
