import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";
import {getAuth} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCMCQUx8UyMNhpFgA_Mzl_WBvLoL58QCrc",
  authDomain: "chew-mon.firebaseapp.com",
  projectId: "chew-mon",
  storageBucket: "chew-mon.appspot.com",
  messagingSenderId: "641475656637",
  appId: "1:641475656637:web:e43b272c7f9c36059a70d4"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const storage = getStorage(app, "gs://chew-mon.appspot.com")

export {app, auth, db, storage}