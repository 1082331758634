import React from "react";
import IProduct from "../../model/product/IProduct";
import MSCartDetail from "./MSCartDetail";

type MSCartProductProp = {
  product: IProduct
}

const MSCartProduct: React.FC<MSCartProductProp> = (props) => {
  const baseStyle: React.CSSProperties = {
    width: "800px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #E2E2E3",
    marginBottom: "50px",
    paddingLeft: "40px",
    paddingRight: "40px",
  }

  const summaryTitle: React.CSSProperties = {
    fontWeight: 600,
    fontSize: "30px",
    paddingTop: "40px",
    paddingBottom: "20px",
  }

  const detailItem: React.CSSProperties = {
    fontWeight: 600,
    fontSize: "24px",
    borderTop: "1px solid #E2E2E3",
    paddingBottom: "20px",
    paddingTop: "30px"
  }

  function buildInitial() {
    if (!props.product.initial.isInCart) return
    return (
      <div style={detailItem}>
        <div>導入費用</div>
        {props.product.initial.items.flatMap(_ => _.toCardDetail())
          .map(cart => <MSCartDetail key={JSON.stringify(cart)} cart={cart}/>)}
      </div>
    )
  }

  function buildPlan() {
    const items = props.product.plan.items.filter(_ => _.isInCart);
    if (items.length === 0) return
    return (
      <div style={detailItem}>
        <div>PLAN</div>
        {items.flatMap(_ => _.toCardDetail())
          .map(cart => <MSCartDetail key={JSON.stringify(cart)} cart={cart}/>)}
      </div>
    )
  }

  function buildOption() {
    const items = props.product.option.items.filter(_ => _.isInCart);
    if (items.length === 0) return
    return (
      <div style={detailItem}>
        <div>OPTION</div>
        {items.flatMap(_ => _.toCardDetail())
          .map(cart => <MSCartDetail key={JSON.stringify(cart)} cart={cart}/>)
        }
      </div>
    )
  }

  return (
    <React.Fragment>
      {props.product.isInCart &&
        <div style={baseStyle}>
          <div style={summaryTitle}>
            {props.product.summary.title}
          </div>
          {buildInitial()}
          {buildPlan()}
          {buildOption()}
        </div>}
    </React.Fragment>)
}

export default MSCartProduct