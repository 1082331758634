class DateUtils {
  static toJpnYMD(date: Date): string {
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
  }

  static toJpnYM(date: Date): string {
    return `${date.getFullYear()}年${date.getMonth() + 1}月`
  }

  static toFormatted(date: Date, format: string = "YYYY-MM-DD hh:mm:ss"): string {
    return format.replace(/YYYY/g, `${date.getFullYear()}`)
      .replace(/MM/g, `0${date.getMonth() + 1}`.slice(-2))
      .replace(/DD/g, `0${date.getDate()}`.slice(-2))
      .replace(/hh/g, `0${date.getHours()}`.slice(-2))
      .replace(/mm/g, `0${date.getMinutes()}`.slice(-2))
      .replace(/ss/g, `0${date.getSeconds()}`.slice(-2))
  }
}

export default DateUtils