import React from "react"
import {useAuthContext} from "../config/GoogleAuthentication";
import {PATHS} from "../../index";
import {Navigate} from "react-router-dom";

const NotFound = () => {
  const user = useAuthContext()
  return user ? <Navigate to={PATHS.PRODUCTS}/> : <div>404 Page Not Found</div>
}

export default NotFound