import PaymentType from "../../../../shared/enum/PaymentType";
import SimpleMerchandise from "../../../../shared/model/merchandise/SimpleMerchandise";
import SelectionType from "../../../../shared/enum/SelectionType";

class SingleInitialItem extends SimpleMerchandise {
  constructor(title: string, description: string, imageUrl: string, price: number) {
    super(title, description, imageUrl, [], "", PaymentType.BUYOUT, price, SelectionType.SINGLE)
  }
}

export default SingleInitialItem