import React from "react";
import ISummary from "../../model/product/summary/ISummary";
import UIImage from "../../../shared/dom/component/UIImage";
import UILabel from "../../../shared/dom/component/UILabel";
import {PaddingProps, RectProps} from "../../../shared/model/props/RectProps";

type MSProductSummaryProp = {
  summary: ISummary
} & RectProps
  & PaddingProps

const MSProductSummary: React.FC<MSProductSummaryProp> = (props) => {

  const css: { [_: string]: React.CSSProperties } = {
    container: {
      width: props.width ?? "100%",
      height: props.height ?? "auto",
      padding: props.padding ?? 0,
      display: "flex",
      flexDirection: "column"
    },
    image: {
      width: "240px",
      height: "150px"
    },
    title: {
      font: "normal normal normal 16px/23px Hiragino Sans",
      letterSpacing: "0px",
      color: "#000000DE",
      opacity: 1,
      fontWeight: 600,
      paddingTop: "20px",
      paddingBottom: "8px"
    },
    body: {
      font: "normal normal normal 14px/20px Hiragino Sans",
      letterSpacing: "0px",
      color: " #000000CC",
      opacity: 1,
    }
  }

  return (
    <div style={css.container} onClick={() => {
      window.location.href = `#${props.summary.title}`
    }}>
      <UIImage width={css.image.width} height={css.image.height} src={props.summary.imageUrl}/>
      <UILabel style={css.title} label={props.summary.title}/>
      <UILabel style={css.body} label={props.summary.description}/>
    </div>
  );
}

export default MSProductSummary;