import React from "react";
import {googleSignOut} from "./GoogleSignIn";
import {useAuthContext} from "../config/GoogleAuthentication";
import UIButton from "./component/UIButton";

const AppFooter = () => {
  const user = useAuthContext()

  const css: { [_: string]: React.CSSProperties } = {
    footer: {
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: "60px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderTop: "1px solid #E1E1E2",
      font: "normal normal normal 16px/22px Lato",
      color: "#00000099",
      backgroundColor: "#fff"
    },
    button: {
      width: "88px",
      height: "25px",
      border: "1px solid rgb(27, 28, 29)",
      borderRadius: "4px",
      opacity: 1,
      font: "13px / 23px Hiragino Sans",
      color: "rgb(27, 28, 29)",
      backgroundColor: "transparent",
      cursor: "pointer",
      marginLeft: "20px"
    },
  }
  return (
    <React.Fragment>
      <footer style={css.footer}>
        <h6>© 2022 Lacicu Inc.</h6>
        {user && <UIButton style={css.button} label={"Log out"} onClick={googleSignOut}/>}
      </footer>
    </React.Fragment>
  )
}

export default AppFooter;