import ISummary from "./summary/ISummary";
import {IInitial} from "./initial/IInitial";
import {IPlan} from "./plan/IPlan";
import {IOption} from "./option/IOption";
import IProduct from "./IProduct";
import TotalPrice from "../../../shared/model/TotalPrice";
import PaymentType from "../../../shared/enum/PaymentType";
import {OrderItem} from "../order/Order";

class Product implements IProduct {
  summary: ISummary
  initial: IInitial
  plan: IPlan
  option: IOption
  get memo(): string {
    if (!this.isInCart) return ""
    return this.plan.items.filter(_ => _.isInCart).map(_ => _.memo).join("\n")
  }

  get isInCart(): boolean {
    return this.initial.isInCart
      || this.plan.items.some(_ => _.isInCart)
      || this.option.items.some(_ => _.isInCart)
  }

  constructor(summary: ISummary, initial: IInitial, plan: IPlan, option: IOption) {
    this.summary = summary
    this.initial = initial
    this.plan = plan
    this.option = option
  }

  calcPrice(): TotalPrice {
    return [this.initial, this.plan, this.option].reduce((prev: TotalPrice, cur) => prev.add(cur.calcPrice()), TotalPrice.empty);
  }

  toOrderItem(paymentType: PaymentType, ...args: string[]): OrderItem[] {
    return [this.initial, this.plan, this.option].flatMap(_ => _.toOrderItem(paymentType, this.summary.title))
  }
}

export default Product;
