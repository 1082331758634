import IPurchasable from "./IPurchasable";
import SelectionType from "../../enum/SelectionType";
import PaymentType from "../../enum/PaymentType";
import TotalPrice from "../TotalPrice";
import {OrderItem} from "../../../main/model/order/Order";
import {ProductTableRowInput} from "../../../main/dom/order/MSTableInput";

class SimplePurchasable implements IPurchasable {
  // constant fields
  quantity: number = 1

  // variable fields
  name: string
  paymentType: PaymentType
  price: number
  selectionType: SelectionType

  constructor(name: string, paymentType: PaymentType, price: number, selectionType: SelectionType) {
    this.name = name
    this.paymentType = paymentType
    this.price = Math.abs(price)
    this.selectionType = selectionType
  }

  calcPrice(): TotalPrice {
    return TotalPrice.build(this.paymentType, this.price * this.quantity)
  }

  toOrderItem(paymentType: PaymentType, ...args: string[]): OrderItem[] {
    if (this.paymentType !== paymentType) return []
    if (this.quantity === 0) return []
    return [new ProductTableRowInput([...args, this.name].filter(_ => _).join(" "), this.price, this.quantity)]
  }
}

export default SimplePurchasable