import React, {CSSProperties, Dispatch, FC, SetStateAction, useId} from "react";
import {IPlan, IPlanItems, TPlanItem} from "../../../model/product/plan/IPlan";
import MSPlanItem from "./MSPlanItem";
import {RectProps} from "../../../../shared/model/props/RectProps";
import GlobalCss from "../../GlobalCss";
import SimplePlan from "../../../model/product/plan/SimplePlan";
import SelectablePlan from "../../../model/product/plan/SelectablePlan";
import UUID from "../../../../shared/utils/UUID";
import ComparablePlanItem from "../../../model/product/plan/ComparablePlanItem";

type MSPlanProp = {
  plan: IPlan
  setOrderStr: Dispatch<SetStateAction<string>>
} & RectProps

const MSPlan: FC<MSPlanProp> = (props) => {

  const radioUid = useId()

  const css: { [_: string]: CSSProperties } = {
    container: {
      width: props.width ?? "100%",
      height: props.height ?? "auto",
      background: "#ECDC6C1A 0% 0% no-repeat padding-box",
      opacity: 1,
      padding: "50px"
    },
    header: {
      display: "flex"
    },
    title: {
      ...GlobalCss.sectionTitle,
    },
    subTitle: {
      marginLeft: "158px"
    },
    IDRadios: {
      marginLeft: "70px",
      display: "flex"
    },
    IDRadioLabel: {
      marginRight: "25px",
      display: "flex",
      alignItems: "center"
    },
    IDText: {
      marginLeft: "5px",
      fontWeight: "500"
    },
    body: {
      display: "flex",
      flexDirection: "row",
      alignItems: "start",
      justifyContent: "center",
      paddingTop: "50px",
      paddingLeft: "50px",
      paddingBottom: "30px",
    },
    itemContainer: {
      marginRight: "50px",
      transition: "transform 0.2s",
    },
    selectedItemContainer: {
      marginRight: "50px",
      transform: "scale(1.1)",
      transition: "transform 0.2s",
    },
    compareWrap: {
      position: "relative",
      height: "100px"
    },
    compare: {
      position: "absolute",
      top: "30px",
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: "#F7FFFF",
      border: "1px solid #BED8E6",
      height: "80px",
      width: "250px",
      paddingLeft: "20px",
      paddingRight: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }

  const showSelect = () => {
    switch (props.plan.constructor) {
      case SimplePlan:
        return (
          <div style={css.subTitle}>
            IDに合わせて3つのプランから選べます
          </div>)
      case SelectablePlan:
        const plan: SelectablePlan = props.plan as SelectablePlan
        return (
          <div style={css.IDRadios}>
            {plan.selects.map((item, index) =>
              selectOptions(item, plan.selectedIndex === index))}
          </div>
        )
    }
  }

  const onChecked = (item: IPlanItems) => {
    props.plan.selectedIndex = props.plan.selects.indexOf(item)
    props.plan.isSelected = props.plan.selectedIndex > -1
    props.setOrderStr(JSON.stringify(props.plan))
  }

  const selectOptions = (item: IPlanItems, selected: boolean) => {
    const id = UUID.generate()
    return (
      <label style={css.IDRadioLabel} key={id} htmlFor={id}>
        <input name={radioUid} type="radio" id={id}
               defaultChecked={selected}
               onChange={() => onChecked(item)}/>
        <span style={css.IDText}>
        {item.name}
        </span>
      </label>
    )
  }

  const onClickPlan = (item: TPlanItem) => {
    if (item.isInCart) {
      item.isInCart = false
    } else {
      props.plan.selects.forEach(plans =>
        plans.items.forEach(item => item.isInCart = false))
      item.isInCart = true
    }
    props.setOrderStr(JSON.stringify(props.plan))
  }

  return (
    <div style={css.container}>
      <div style={css.header}>
        <div style={css.title}>
          PLAN
        </div>
        {showSelect()}
      </div>
      <div style={css.body}>
        {props.plan.items.map((item, index) =>
          <React.Fragment key={index}>
            <div style={item.isInCart ? css.selectedItemContainer : css.itemContainer}>
              <MSPlanItem setOrderStr={props.setOrderStr} onClickPlan={onClickPlan}
                          width={"206px"} height={"auto"} item={item}/>
              {item instanceof ComparablePlanItem && item.compare &&
                  <div style={css.compareWrap}>
                      <div style={css.compare}>
                        {item.compare}
                      </div>
                  </div>
              }
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default MSPlan