import {createContext, FC, Fragment, ReactNode, useContext, useEffect, useState} from 'react';
import {getAuth, onAuthStateChanged, User} from "firebase/auth";
import {googleSignOut} from "../dom/GoogleSignIn";

const AuthContext = createContext<User | null>(null)

export function useAuthContext() {
  return useContext(AuthContext)
}

type AuthProviderProps = {
  children: ReactNode
}

const AuthProvider: FC<AuthProviderProps> = (props) => {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const auth = getAuth()
    const unsubscribed = onAuthStateChanged(auth, (user) => {
      setUser(user)
      setLoading(false)
    })
    return () => {
      unsubscribed()
    };
  }, [])

  return (
    <Fragment>
      {loading ? <p>loading...</p> :
        <AuthContext.Provider value={user}>
          {props.children}
        </AuthContext.Provider>}
    </Fragment>
  )
}

export default AuthProvider