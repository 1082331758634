import React, {FC} from "react";
import ProductUtils from "../../../shared/utils/ProductUtils";
import UUID from "../../../shared/utils/UUID";
import PaymentType from "../../../shared/enum/PaymentType";
import {OrderDetail, OrderItem} from "../../model/order/Order";

const css: { [_: string]: React.CSSProperties } = {
  tableTitle: {
    paddingTop: "30px",
    paddingBottom: "10px",
    fontWeight:"600"
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    backgroundColor: "#fff",
    border:"1px solid #000"
  },
  th1: {
    fontWeight: "normal",
    backgroundColor: "#aaa",
    textAlign: "left",
    paddingLeft: "20px",
    border:"1px solid #000"
  },
  th2: {
    fontWeight: "normal",
    backgroundColor: "#aaa",
    textAlign: "center",
    width: "140px",
    border:"1px solid #000",
  },
  th3: {
    fontWeight: "normal",
    backgroundColor: "#aaa",
    textAlign: "center",
    width: "80px",
    border:"1px solid #000",
  },
  th4: {
    fontWeight: "normal",
    backgroundColor: "#aaa",
    textAlign: "center",
    width: "140px",
    border:"1px solid #000",
  },
  th5: {
    fontWeight: "normal",
    backgroundColor: "#aaa",
    width: "40px",
    border:"1px solid #000",
  },
  td:{
    border:"1px solid #000",
  },
  ptd: {
    border:"1px solid #000",
    textAlign: "right"
  }
}

interface ITableRow {
  id: string
  type: RowType
}

enum RowType {
  PRODUCT,
  MEMO,
}

class ProductTableRow implements ITableRow {
  static decode(row: any): ProductTableRow {
    return new ProductTableRow(row.name, row.price, row.quantity, row.id)
  }

  readonly id: string = UUID.generate()
  type: RowType = RowType.PRODUCT
  name: string
  price: number
  quantity: number

  constructor(name: string, price: number, quantity: number, id: string = UUID.generate()) {
    this.name = name;
    this.price = price;
    this.quantity = quantity;
  }
}

type MSProductTableRowProp = {
  item: ProductTableRow
}

const MSProductTableRow: FC<MSProductTableRowProp> = (props) => {
  console.log(props)
  return (
    <tr>
      <td style={css.td}>{props.item.name}</td>
      <td style={css.ptd}>{ProductUtils.formatPrice(props.item.price)}</td>
      <td style={css.ptd}>{props.item.quantity}</td>
      <td style={css.ptd}>{ProductUtils.formatPrice(props.item.price * props.item.quantity)}</td>
    </tr>
  )
}

class MemoTableRow implements ITableRow {
  static decode(row: any): MemoTableRow {
    return new MemoTableRow(row.memo, row.id)
  }

  readonly id: string
  type: RowType = RowType.MEMO
  memo: string

  constructor(memo: string, id: string = UUID.generate()) {
    this.id = id
    this.memo = memo
  }
}

type MSMemoTableRowProp = {
  item: MemoTableRow
}

const MSMemoTableRow: FC<MSMemoTableRowProp> = (props) => {
  return (
    <tr>
      <td colSpan={4}>{props.item.memo} </td>
    </tr>
  )
}

type MSTableProp = {
  detail: OrderDetail
}

const MSTable: FC<MSTableProp> = (props) => {
  const Header = () => {
    switch (props.detail.paymentType) {
      case PaymentType.BUYOUT:
        return <div style={css.tableTitle}>御見積詳細（初期費用）</div>
      case PaymentType.SUBSCRIPTION:
        return <div style={css.tableTitle}>御見積詳細（月額費用）</div>
    }
  }
  const TableHeader = () => {
    return (
      <tr>
        <th style={css.th1}>品目</th>
        <th style={css.th2}>単価</th>
        <th style={css.th3}>数量</th>
        <th style={css.th4}>金額（税抜）</th>
      </tr>
    )
  }

  return (
    <div>
      <Header/>
      <table style={css.table}>
        <thead>
        <TableHeader/>
        </thead>
        <tbody>
        {props.detail.items.map(item =>
          <MSTableRow key={item.id} item={item}/>)}
        </tbody>
      </table>
    </div>
  )
}

type MSTableRowProp = {
  item: OrderItem
}

const MSTableRow: FC<MSTableRowProp> = (props) => {
  const buildRow = () => {
    if (props.item.type === RowType.PRODUCT) {
      return <MSProductTableRow item={props.item as ProductTableRow}/>
    } else if (props.item.type === RowType.MEMO) {
      return <MSMemoTableRow item={props.item as MemoTableRow}/>
    }
  }
  return (
    <React.Fragment>
      {buildRow()}
    </React.Fragment>
  )
}

export {MSTable, ProductTableRow, MemoTableRow, RowType}