import IMerchandise from "./IMerchandise";
import IPurchasable from "../purchasable/IPurchasable";
import PaymentType from "../../enum/PaymentType";
import TotalPrice from "../TotalPrice";
import CartDetail from "../../../main/model/cart/CartDetail";
import SelectionType from "../../enum/SelectionType";
import SimplePurchasable from "../purchasable/SimplePurchasable";
import {OrderItem} from "../../../main/model/order/Order";
import {ProductTableRowInput} from "../../../main/dom/order/MSTableInput";

class DoubleMerchandise implements IMerchandise {
  readonly title: string
  readonly description: string
  readonly imageUrl: string
  readonly notes: string[]
  purchasables: IPurchasable[]

  get purchasable(): IPurchasable {
    return this.purchasables[0]
  }

  get additionalPurchasable(): IPurchasable {
    return this.purchasables[1]
  }

  protected constructor(title: string, description: string, imageUrl: string, notes: string[], paymentType: PaymentType, selectionType: SelectionType,
                        name1: string, price1: number, name2: string, price2: number) {
    this.description = description
    this.imageUrl = imageUrl
    this.notes = notes
    this.title = title
    this.purchasables = [
      new SimplePurchasable(name1, paymentType, price1, selectionType),
      new SimplePurchasable(name2, paymentType, price2, selectionType)
    ]
  }

  calcPrice(): TotalPrice {
    return this.purchasables.reduce((prev: TotalPrice, cur) => prev.add(cur.calcPrice()), TotalPrice.empty)
  }

  toCardDetail(): CartDetail[] {
    return [
      new CartDetail(this.title, this.imageUrl, this.description, this.purchasable),
      new CartDetail(this.title, this.imageUrl, this.additionalPurchasable.name, this.additionalPurchasable)
    ]
  }

  toOrderItem(paymentType: PaymentType, ...args: string[]): OrderItem[] {
    return this.purchasables
      .filter(_ => _.paymentType === paymentType)
      .map(_ => new ProductTableRowInput([...args, this.title, _.name].filter(_ => _).join(" "), _.price, _.quantity))
  }
}

export default DoubleMerchandise