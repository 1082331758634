import PaymentType from "../../../../shared/enum/PaymentType";
import SelectionType from "../../../../shared/enum/SelectionType";
import DoubleMerchandise from "../../../../shared/model/merchandise/DoubleMerchandise";

class DoubleInitialItem extends DoubleMerchandise {
  constructor(title: string, description: string, imageUrl: string, price1: number, name2: string, price2: number) {
    super(title, description, imageUrl, [], PaymentType.BUYOUT, SelectionType.SINGLE, "", price1, name2, price2)
  }
}

export default DoubleInitialItem