import React, {FC} from 'react';
import UITextField from "../../../shared/dom/component/UITextField";
import {Order} from "../../model/order/Order";
import UILabel from "../../../shared/dom/component/UILabel";

type MSOSConfirmInputProp = {
  order: Order
}

const MSOSConfirmInput: FC<MSOSConfirmInputProp> = (props) => {
  const css: { [_: string]: React.CSSProperties } = {
    orderedDateWrap: {
      paddingLeft: "220px"
    },
    inputCss: {
      width: "100%",
      height: "38px",
      paddingLeft: "10px",
      paddingRight: "10px",
      border: "1px solid #DEDEDF",
      borderRadius: "4px",
      marginTop: "4px"
    },
  }

  return (
    <React.Fragment>
      <div style={css.orderedDateWrap}>
        <UILabel label={"注文日 "}/>
        <UILabel label={props.order?.confirmAt ?? ""}/>
      </div>
      <UITextField inputCss={css.inputCss} label={"住所"} placeholder={"住所"}
                   default={props.order.confirmCompanyAddress}
                   onChange={(value) => (props.order as Order).confirmCompanyAddress = value}
                   required={true}/>
      <UITextField inputCss={css.inputCss} label={"法人名"} placeholder={"法人名"}
                   default={props.order.confirmCompanyName}
                   onChange={(value) => (props.order as Order).confirmCompanyName = value}
                   required={true}/>
      <UITextField inputCss={css.inputCss} label={"部署名"} placeholder={"部署名"}
                   default={props.order.confirmCompanyDepartment}
                   onChange={(value) => (props.order as Order).confirmCompanyDepartment = value}/>
      <UITextField inputCss={css.inputCss} label={"担当者名"} placeholder={"担当者名"}
                   default={props.order.confirmCompanyManager}
                   onChange={(value) => (props.order as Order).confirmCompanyManager = value}
                   required={true}/>
    </React.Fragment>
  )
}

export default MSOSConfirmInput
