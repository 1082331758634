import {ref, uploadBytes} from "firebase/storage";
import {db, storage} from "../config/FirebaseApp";
import {UploadResult} from "@firebase/storage";
import {addDoc, collection} from "firebase/firestore";
import Logger from "../Logger";

enum OrderStatus {
  UNREAD = "unread",
  READ = "read",
  CONFIRMED = "confirmed",
}

class FirebaseUtils {

  static generateFileName(): string {
    const date = new Date()
    const dateStr = `${date.getFullYear()}${date.getMonth()}${date.getDate()}`

    const max = 999
    const number = Math.floor(Math.random() * (max + 1))
    const numberStr = `000${number}`.slice(-3)

    return `${dateStr}_${numberStr}`
  }

  static uploadPdf(blob: Blob, filePath: string): Promise<UploadResult> {
    const userImageRef = ref(storage, filePath)
    return uploadBytes(userImageRef, blob)
  }

  static async sendMail(mail: object) {
    const result = await addDoc(collection(db, "mail"), mail)
    Logger.debug("sendMail result:", result)
  }
}

export {OrderStatus}
export default FirebaseUtils