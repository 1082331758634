import PaymentType from "../enum/PaymentType";
import React from "react";

class ProductUtils {
  static formatPrice(price: number, unit: boolean = false): string {
    return `${(price ?? 0).toLocaleString()}${unit ? "円" : ""}`
  }

  static formatPriceWithTax(price: number, tax: number = 0.1, unit: boolean = false): string {
    const taxedPrice = Math.floor(price * 100.0 * (1 + tax) / 100)
    return this.formatPrice(taxedPrice, unit)
  }

  static pricePrefix(paymentType: PaymentType): React.ReactNode {
    switch (paymentType) {
      case PaymentType.BUYOUT:
        return null
      case PaymentType.SUBSCRIPTION:
        return <span>月額</span>
    }
  }

  static formatPriceWithPrefix(paymentType: PaymentType, price: number): React.ReactNode {
    return (
      <React.Fragment>
        {this.pricePrefix(paymentType)}{this.formatPrice(price, true)}
      </React.Fragment>
    )
  }
}

export default ProductUtils