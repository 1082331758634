import IProduct from "./IProduct";
import TotalPrice from "../../../shared/model/TotalPrice";
import IPurchasableProtocol from "../../../shared/model/purchasable/IPurchasableProtocol";
import PaymentType from "../../../shared/enum/PaymentType";
import {OrderItem} from "../order/Order";

class AllProduct implements IPurchasableProtocol {
  products: IProduct[];

  constructor(products: IProduct[]) {
    this.products = products;
  }

  calcPrice(): TotalPrice {
    return this.products.reduce((prev: TotalPrice, cur) => prev.add(cur.calcPrice()), TotalPrice.empty);
  }

  toOrderItem(paymentType: PaymentType, ...args: string[]): OrderItem[] {
    return this.products.flatMap(_ => _.toOrderItem(paymentType))
  }

  buildMemo(): string {
    return this.products.filter(_ => _.isInCart)
      .map(p => p.memo).join("\n")
  }
}

export default AllProduct;
