import React, {CSSProperties} from "react";
import UIUnit from "./UIUnit";
import Logger from "../../Logger";

type UITextFieldProp = {
  label?: string
  placeholder?: string
  onChange?: (value: string) => void
  required?: boolean
  default?: string
  containerCss?: CSSProperties
  labelCss?: CSSProperties
  inputCss?: CSSProperties
}

const UITextField: React.FC<UITextFieldProp> = (props) => {
  const invalidBorder = "1px solid rgb(256, 0, 0)"

  const buildBaseCss = () => {
    if (props.default || !props.required) {
      return props.inputCss
    }
    return {...props.inputCss, border: invalidBorder}
  }

  return (
    <UIUnit tag={"div"} style={props.containerCss}>
      {props.label &&
          <UIUnit tag={"div"} style={props.labelCss}>
            {props.label}
          </UIUnit>
      }
      <input style={buildBaseCss()} type={"text"}
             defaultValue={props.default}
             placeholder={props.placeholder ?? ""}
             onChange={(event) => {
               Logger.debug(`UITextField.onChange event:{${event}`)
               if (props.required && !event.target.value) {
                 event.target.style.border = invalidBorder
               } else {
                 if (props.inputCss?.border) {
                   event.target.style.border = props.inputCss?.border as string
                 }
               }
               props.onChange?.(event.target.value)
             }}/>

    </UIUnit>
  )
}

export default UITextField