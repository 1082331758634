import React, {CSSProperties} from 'react';
import AppBody from "../../shared/dom/AppBody";
import {PATHS} from "../../index";
import MSCartPrice from "./cart/MSCartPrice";
import MSCartProduct from "./cart/MSCartProduct";
import paymentType from "../../shared/enum/PaymentType";
import PaymentType from "../../shared/enum/PaymentType";
import AppHeader from "../../shared/dom/AppHeader";
import AppFooter from "../../shared/dom/AppFooter";
import AppHeaderButton from "../../shared/dom/AppHeaderButton";
import AllProduct from "../model/product/AllProduct";
import {Link, useLocation} from "react-router-dom";
import {AppConfig} from "../../shared/config/AppConfig";

const MSPageCart = () => {
  let valid = false
  const location = useLocation()
  if (location.state && location.state instanceof AllProduct) {
    valid = true
  }
  const data = location.state as AllProduct

  const css: { [_: string]: CSSProperties } = {
    title: {
      textAlign: "center",
      fontSize: "30px",
      marginTop: 0,
      paddingTop: "20px"
    },
    buttonWrapper: {
      marginRight: "20px"
    },
    priceTitle: {
      textAlign: "center",
      fontSize: "24px"
    },
    detailTitle: {
      textAlign: "center",
      fontSize: "24px",
      marginTop: "80px"
    }
  }

  function priceLabel(paymentType: paymentType): string {
    switch (paymentType) {
      case PaymentType.BUYOUT:
        return "初期費用"
      case PaymentType.SUBSCRIPTION:
        return "月額費用"
    }
  }

  function sum() {
    const totalPrice = data.calcPrice()
    return totalPrice.prices.map((price, index) =>
      <MSCartPrice key={index} title={priceLabel(price.paymentType)} price={price.total}/>)
  }

  function details() {
    return data.products.map((product, index) =>
      <MSCartProduct key={index} product={product}/>)
  }

  return (
    <React.Fragment>
      {valid ?
        <React.Fragment>
          <AppHeader>
            <div style={css.buttonWrapper}>
              <AppHeaderButton to={PATHS.PRODUCTS} label={"戻る"} allProduct={data}/>
            </div>
            <AppHeaderButton to={PATHS.ORDER} label={"見積へ"} allProduct={data}/>
            {AppConfig.debug && <button onClick={() => console.log(data)}> print object</button>}
          </AppHeader>
          <AppBody>
            <React.Fragment>
              <h1 style={css.title}>カート一覧</h1>
              <h4 style={css.priceTitle}>費用一覧</h4>
              <div>
                {sum()}
              </div>
              <h4 style={css.detailTitle}>詳細一覧</h4>
              <div>
                {details()}
              </div>
            </React.Fragment>
          </AppBody>
          <AppFooter/>
        </React.Fragment>
        : <Link style={css.priceTitle} to={PATHS.PRODUCTS}>
          トップに戻る
        </Link>
      }
    </React.Fragment>
  )
}

export default MSPageCart
