import React from "react";
import IPurchasable from "../../../shared/model/purchasable/IPurchasable";
import StyleProps from "../../../shared/model/props/StyleProps";
import UILabel from "../../../shared/dom/component/UILabel";
import ProductUtils from "../../../shared/utils/ProductUtils";

type UICartPriceProp = {
  purchasable: IPurchasable
} & StyleProps

const UICartPrice: React.FC<UICartPriceProp> = (props) => {
  const price = ProductUtils.formatPriceWithPrefix(props.purchasable.paymentType, props.purchasable.price * props.purchasable.quantity)
  return (
    <React.Fragment>
      <UILabel style={props.style} label={price}/>
    </React.Fragment>
  )
}

export default UICartPrice