import {IPlan, IPlanItems, PlanItems, TPlanItem} from "./IPlan";
import TotalPrice from "../../../../shared/model/TotalPrice";
import PaymentType from "../../../../shared/enum/PaymentType";
import {OrderItem} from "../../order/Order";

class SimplePlan implements IPlan {
  selects: IPlanItems[]
  isSelected: boolean = true
  selectedIndex: number = 0

  get items(): TPlanItem[] {
    return this.selects[0].items
  }

  constructor(items: TPlanItem[]) {
    this.selects = [new PlanItems("", items)]
  }

  calcPrice(): TotalPrice {
    return this.items.filter(_ => _.isInCart).reduce((prev: TotalPrice, cur) => prev.add(cur.calcPrice()), TotalPrice.empty)
  }

  toOrderItem(paymentType: PaymentType, ...args: string[]): OrderItem[] {
    return this.items.filter(_ => _.isInCart).flatMap(_ => _.toOrderItem(paymentType, ...args))
  }
}

export default SimplePlan
