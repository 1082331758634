import React from 'react';
import AppBody from "../../shared/dom/AppBody";
import AppHeader from "../../shared/dom/AppHeader";
import AppFooter from "../../shared/dom/AppFooter";
import MSOrderList from "./orderlist/MSOrderList";

const MSPageOrders = () => {
  return (
    <React.Fragment>
      <AppHeader/>
      <AppBody>
        <MSOrderList/>
      </AppBody>
      <AppFooter/>
    </React.Fragment>
  )
}

export default MSPageOrders
