import React, {ReactNode} from "react";
import StyleProps from "../../model/props/StyleProps";

type UIButtonProp = {
  label: ReactNode
  onClick?: () => void
} & StyleProps

const UIButton: React.FC<UIButtonProp> = (props) => {
  return (
    <React.Fragment>
      <button style={props.style} onClick={(event) => {
        event.stopPropagation()
        props.onClick?.()
      }}>{props.label}</button>
    </React.Fragment>
  );
}

export default UIButton;