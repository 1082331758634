import React, {CSSProperties, FC} from "react";

type UILoadingProps = {
  loading: boolean
}

const UILoading: FC<UILoadingProps> = (props) => {
  const css: { [_: string]: CSSProperties } = {
    loader: {
      height: "10vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    carBody: {
      animation: "shake 0.2s ease-in-out infinite alternate",
    },
    carLine: {
      transformOrigin: "center right",
      strokeDasharray: "22",
      animation: "line 0.8s ease-in-out infinite",
      animationFillMode: "both",
    },
    carLineTop: {
      animationDelay: "0s",
    },
    carLineMiddle: {
      animationDelay: "0.2s",
    },
    carLineBottom: {
      animationDelay: "0.4s",
    },
  }

  const homeCss: { [_: string]: CSSProperties } = {
    box: {
      fill: "none",
    },
    path: {
      fill: "none",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "3px",
    },
  }

  return (
    <React.Fragment>
      <style>
        {`@keyframes shake {
          0% {
            transform: translateY(-1%);
          }
          100% {
            transform: translateY(3%);
          }
        }

        @keyframes line {
            0% {
              stroke-dashoffset: 22;
          }
            25% {
              stroke-dashoffset: 22;
          }
            50% {
              stroke-dashoffset: 0;
          }
            51% {
              stroke-dashoffset: 0;
          }
            80% {
              stroke-dashoffset: -22;
          }
            100% {
              stroke-dashoffset: -22;
          }
        }
        `}
      </style>
      {props.loading
        ? <div style={css.loader}>
          <svg width="102" height="40" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(2 1)" stroke="#002742" fill="none" fillRule={"evenodd"} strokeLinecap={"round"}
               strokeLinejoin={"round"}>
              <path style={css.carBody} strokeWidth={3}
                    d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01"/>
              <ellipse strokeWidth={3.2} fill="#FFF" cx="83.493" cy="30.25" rx="6.922" ry="6.808"/>
              <ellipse strokeWidth={3.2} fill="#FFF" cx="46.511" cy="30.25" rx="6.922" ry="6.808"/>
              <path style={{...css.carLine, ...css.carLineTop}} d="M22.5 16.5H2.475" strokeWidth={3}/>
              <path style={{...css.carLine, ...css.carLineMiddle}} d="M20.5 23.5H.4755" strokeWidth={3}/>
              <path style={{...css.carLine, ...css.carLineBottom}} d="M25.5 9.5h-19" strokeWidth={3}/>
            </g>
          </svg>
        </div>
        : <div style={css.loader}>
          <svg width="102" height="40" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(2 1)" stroke="#002742" fill="none" fillRule={"evenodd"} strokeLinecap={"round"}
               strokeLinejoin={"round"}>
              <path strokeWidth={3}
                    d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01"/>
              <ellipse strokeWidth={3.2} fill="#FFF" cx="83.493" cy="30.25" rx="6.922" ry="6.808"/>
              <ellipse strokeWidth={3.2} fill="#FFF" cx="46.511" cy="30.25" rx="6.922" ry="6.808"/>
            </g>
          </svg>
          <svg width="90" height="90" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
            <polyline style={homeCss.path} points="4 23 24 6 44 23"/>
            <polyline style={homeCss.path} points="28 42 39 42 39 26"/>
            <polyline style={homeCss.path} points="9 26 9 42 20 42"/>
            <polyline style={homeCss.path} points="20 42 20 30 28 30 28 42"/>
          </svg>
        </div>}
    </React.Fragment>
  )
}

export default UILoading