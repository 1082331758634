import ISummary from "./ISummary";

class Summary implements ISummary {
  title: string
  imageUrl: string
  description: string

  constructor(title: string, imageUrl: string, description: string) {
    this.title = title;
    this.imageUrl = imageUrl;
    this.description = description;
  }
}

export default Summary