import React, {FC} from "react";
import UIUnit from "./UIUnit";
import StyleProps from "../../model/props/StyleProps";
import Logger from "../../Logger";

type UISelectProp = {
  placeholder?: string
  default?: string
  options: string[]
  onChange?: (_: string) => void
} & StyleProps

const css: { [_: string]: React.CSSProperties } = {
  selectCss: {
    width: "126px",
    height: "38px",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: "1px solid #DEDEDF",
    borderRadius: "4px",
  }
}

const UISelect: FC<UISelectProp> = (props) => {
  console.log("props.default", props.default)
  return (
    <React.Fragment>
      <UIUnit tag={"div"} style={props.style}>
        <select style={css.selectCss} defaultValue={props.default ?? 0} onChange={(event) => {
          Logger.debug("UISelect.onChange event", event)
          props.onChange?.(event.target.value)
        }}>
          {props.placeholder &&
              <option value={0} disabled={true}>{props.placeholder}</option>}
          {props.options.map((val, index) =>
            <option key={index} value={val}>{val}</option>)}
        </select>
      </UIUnit>
    </React.Fragment>
  );
}

export default UISelect