import Product from "../../main/model/product/Product";
import Summary from "../../main/model/product/summary/Summary";
import Initial from "../../main/model/product/initial/Initial";
import SingleInitialItem from "../../main/model/product/initial/SingleInitialItem";
import SimplePlan from "../../main/model/product/plan/SimplePlan";
import Option from "../../main/model/product/option/Option";
import AdditionalPlanItem from "../../main/model/product/plan/AdditionalPlanItem";
import SimpleOptionItem from "../../main/model/product/option/SimpleOptionItem";
import SelectionType from "../../shared/enum/SelectionType";
import PaymentType from "../../shared/enum/PaymentType";
import DoubleInitialItem from "../../main/model/product/initial/DoubleInitialItem";

const imagePath = "/products/jukencompass"

const summary = new Summary("受験コンパス",
  "https://www.lacicu.co.jp/wp/wp-content/themes/standard/images/service/service_lineup-jukencompass.jpg",
  "受験コンパスは、生徒の学力・志望校を設定するだけで、“個別カリキュラム作成”が作成できる学習計画作成アプリケーションです。")

const initial = new Initial([
  new SingleInitialItem("初期設定サポート", "1校舎目", `${imagePath}/support.jpg`, 200000),
  new DoubleInitialItem("資料料金", "5冊1セット", `${imagePath}/document.jpg`, 25000, "送料", 1500),
])

const sPlanMemo = ` ■ システム（受験コンパス）の最低契約期間は月額発生月より1年間となっております。
 ■ 受験コンパスの利用料は、生徒利用者が10名を超えた場合は、追加ID費用が発生致します。
 ■ 初期費用のお支払いは、ご注文いただいた月の翌月となります。`

const mPlanMemo = ` ■ システム（受験コンパス）の最低契約期間は月額発生月より1年間となっております。
 ■ 受験コンパスの利用料は、生徒利用者が50名を超えた場合は、追加ID費用が発生致します。
 ■ 初期費用のお支払いは、ご注文いただいた月の翌月となります。`

const lPlanMemo = ` ■ システム（受験コンパス）の最低契約期間は月額発生月より1年間となっております。
 ■ 受験コンパスの利用料は、生徒利用者が100名を超えた場合は、追加ID費用が発生致します。
 ■ 初期費用のお支払いは、ご注文いただいた月の翌月となります。`

const plans = new SimplePlan([
  new AdditionalPlanItem("Sプラン", `${imagePath}/planS.jpg`, ["10ID含む"], 20000, "1ID", 1600, sPlanMemo),
  new AdditionalPlanItem("Mプラン", `${imagePath}/planM.jpg`, ["50ID含む"], 70000, "1ID", 1400, mPlanMemo),
  new AdditionalPlanItem("Lプラン", `${imagePath}/planL.jpg`, ["100ID含む"], 110000, "1ID", 1100, lPlanMemo),
])

const options = new Option([
  new SimpleOptionItem("初期サポート", "2校舎目以降", `${imagePath}/support.jpg`, PaymentType.BUYOUT, 30000, SelectionType.COUNT_UP),
  new SimpleOptionItem("資料追加", "1セット", `${imagePath}/document.jpg`, PaymentType.BUYOUT, 25000, SelectionType.COUNT_UP),
])

const PJukenCompass: Product = new Product(summary, initial, plans, options)
export default PJukenCompass;