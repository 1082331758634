import React, {CSSProperties} from "react";
import {RectProps} from "../../model/props/RectProps";

type UIImageProp = {
  src: string
} & RectProps

const UIImage: React.FC<UIImageProp> = (props) => {

  const css: CSSProperties = {
    width: props.width ?? "100%",
    height: props.height ?? "100%",
  }

  return (
    <React.Fragment>
      <img style={css} src={props.src}/>
    </React.Fragment>
  );
}

export default UIImage;