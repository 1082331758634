import PaymentType from "../../../shared/enum/PaymentType";
import UUID from "../../../shared/utils/UUID";
import DateUtils from "../../../shared/utils/DateUtils";
import {MemoTableRow, ProductTableRow, RowType} from "../../dom/confirm/MSTable";

enum OrderSpan {
  TWO_WEEK = "発行日より2週間",
  FOUR_WEEK = "発行日より4週間"
}

class Order {
  static get empty(): Order {
    return new Order([])
  }

  companyName: string = ""
  companyManager: string = ""
  companyEmail: string = ""

  orderAt: string = ""
  orderSpan: string = OrderSpan.TWO_WEEK

  managerName: string = ""
  managerEmail: string = ""

  memo: string = ""

  startFrom: string = ""
  payingFrom: string = ""

  details: OrderDetail[]

  confirmAt: string = ""
  confirmCompanyAddress: string = ""
  confirmCompanyName: string = ""
  confirmCompanyDepartment: string = ""
  confirmCompanyManager: string = ""

  detailOf(paymentType: PaymentType): OrderDetail {
    return this.details
      .filter(d => d.paymentType === paymentType)[0]
  }

  constructor(details: OrderDetail[]) {
    this.details = details;
  }
}

class OrderDetail {
  id: string = UUID.generate()
  paymentType: PaymentType
  items: OrderItem[]

  static decode(object: any): OrderDetail {
    const items = object.items.flatMap((i: any) => {
      if (i.type === RowType.PRODUCT) {
        return [ProductTableRow.decode(i)]
      } else if (i.type === RowType.MEMO) {
        return [MemoTableRow.decode(i)]
      }
      return []
    })
    let order = new OrderDetail(object.paymentType, items)
    order.id = object.id
    return order
  }

  constructor(paymentType: PaymentType, items: OrderItem[]) {
    this.paymentType = paymentType
    this.items = items
  }

  get total(): number {
    return this.items
      .filter(item => item.type === RowType.PRODUCT)
      .map(i => {
        const item = i as ProductTableRow
        return item.price * item.quantity
      }).reduce((p1, p2) => p1 + p2, 0)
  }

  get(id: string): OrderItem | undefined {
    return this.items.filter(i => i.id === id)?.[0]
  }

  add(item: OrderItem) {
    this.items.push(item)
  }

  remove(item: OrderItem): OrderItem[] {
    const target = this.get(item.id)
    if (!target) return [...this.items]

    const index = this.items.indexOf(target)
    if (index < 0) return [...this.items]

    this.items.splice(index, 1)
    return [...this.items]
  }
}

type OrderItem = ProductTableRow | MemoTableRow

class OrderInput extends Order {
  static get empty(): OrderInput {
    return new OrderInput([], "")
  }

  constructor(details: OrderDetail[], memo: string) {
    super(details)
    this.memo = memo;
  }

  orderAt: string = DateUtils.toJpnYMD(new Date())
  orderAtDate: Date = new Date()

  startFrom: string = DateUtils.toJpnYM(new Date())
  startFromDate: Date = new Date()
  payingFrom: string = DateUtils.toJpnYM(new Date())
  payingFromDate: Date = new Date()
}

export {OrderSpan}
export type {OrderItem}
export {Order, OrderInput, OrderDetail}
