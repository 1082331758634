import {IPlan, IPlanItems, PlanItems, TPlanItem} from "./IPlan";
import TotalPrice from "../../../../shared/model/TotalPrice";
import PaymentType from "../../../../shared/enum/PaymentType";
import {OrderItem} from "../../order/Order";


class SelectablePlan implements IPlan {

  selectedIndex: number = 0
  isSelected: boolean = false
  selects: IPlanItems[]

  get items(): TPlanItem[] {
    return this.selects[this.selectedIndex].items
  }

  // get isInCart(): boolean {
  //   return this.items.some(_ => _.isInCart)
  // }

  constructor(selects: PlanItems[]) {
    this.selects = selects
  }

  calcPrice(): TotalPrice {
    return this.items.filter(_ => _.isInCart).reduce((prev: TotalPrice, cur) => prev.add(cur.calcPrice()), TotalPrice.empty);
  }

  toOrderItem(paymentType: PaymentType, ...args: string[]): OrderItem[] {
    return this.items.filter(_ => _.isInCart).flatMap(_ => _.toOrderItem(paymentType,...args))
  }
}

export default SelectablePlan