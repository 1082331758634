import React, {FC} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import MSPageConfirmOrder from "./main/dom/MSPageConfirmOrder";
import AuthProvider from "./shared/config/GoogleAuthentication";
import PrivateRoute from "./shared/routes/PrivateRoute";
import NotFound from "./shared/dom/NotFound";
import MSPageProducts from "./main/dom/MSPageProducts";
import MSPageCart from "./main/dom/MSPageCart";
import MSPageCreateOrder from "./main/dom/MSPageCreateOrder";
import MSPageOrders from "./main/dom/MSPageOrders";
import ProductsData from "./data/ProductsData";
import AllProduct from "./main/model/product/AllProduct";
import ScrollToTop from "./shared/routes/ScrollToTop";

export const PATHS = {
  PRODUCTS: "/products",
  CART: "/cart",
  ORDER: "/order",
  SENT_MAIL: "/sentMail",
  ADMIN_ORDERS: "/admin/orders",
} as const

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const GlobalRoot = () => {
  const data = ProductsData
  return <Root data={data}/>
}

type RootProps = {
  data: AllProduct
}

const Root: FC<RootProps> = (props) => {
  return (
    <AuthProvider>
      <React.StrictMode>
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              {/*認証不要ページ*/}
              <Route path={"*"} element={<NotFound/>}/>
              <Route path={`${PATHS.ORDER}/:orderId`} element={<MSPageConfirmOrder/>}/>

              {/*認証必須ページ*/}
              <Route path={PATHS.PRODUCTS} element={<PrivateRoute><MSPageProducts data={props.data}/></PrivateRoute>}/>
              <Route path={PATHS.CART} element={<PrivateRoute><MSPageCart/></PrivateRoute>}/>
              <Route path={PATHS.ORDER} element={<PrivateRoute><MSPageCreateOrder/></PrivateRoute>}/>
              <Route path={PATHS.ADMIN_ORDERS} element={<PrivateRoute><MSPageOrders/></PrivateRoute>}/>
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </React.StrictMode>
    </AuthProvider>
  )
}

root.render(
  <GlobalRoot/>
)
