import React, {FC} from "react";
import AllProduct from "../../model/product/AllProduct";
import PaymentType from "../../../shared/enum/PaymentType";
import UILabel from "../../../shared/dom/component/UILabel";
import UIPrice from "../component/UIPrice";

type MSCartSummaryProp = {
  data: AllProduct
}

const MSCartSummary: React.FC<MSCartSummaryProp> = (props) => {
  const totalPrice = props.data.calcPrice()

  type PriceLabelProp = {
    title: string
    price: number
  }

  const PriceLabel: FC<PriceLabelProp> = (props) => {
    return (
      <div style={css.priceLabel}>
        <UILabel label={props.title}/>
        <UIPrice price={props.price}/>
      </div>
    )
  }

  const css: { [_: string]: React.CSSProperties } = {
    summary: {
      position: "fixed",
      top: "100px",
      left: "calc(50vw + 430px)",
      width: "270px",
      boxShadow: "0px 6px 9px #00000029",
      backgroundColor: "#fff",
      borderRadius: "4px",
      fontSize:"14px"
    },
    priceLabel: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "15px",
      paddingBottom: "15px",
      paddingLeft: "30px",
      paddingRight: "30px",
      borderBottom: "1px solid #E1E1E2"
    }
  }

  return (
    <div style={css.summary}>
      <PriceLabel title={"初期費用（税抜）"} price={totalPrice.getSumOf(PaymentType.BUYOUT)}/>
      <PriceLabel title={"月額費用（税抜）"} price={totalPrice.getSumOf(PaymentType.SUBSCRIPTION)}/>
    </div>
  );
}

export default MSCartSummary