import React, {CSSProperties} from "react";
import UIImage from "./component/UIImage";

type AppHeaderProps = {
  children?: React.ReactNode
}

const AppHeader = (props: AppHeaderProps) => {
  const fixedHeaderCss: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 3px 6px #00000029",
  }

  const headerInnerCss: React.CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "920px",
    maxWidth: "100%",
    height: "64px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "20px",
    paddingRight: "20px"
  }

  const headerSpaceCss: CSSProperties = {
    height: "64px"
  }

  const buttonAreaCss: CSSProperties = {
    display: "flex",
  }

  return (
    <React.Fragment>
      <header className="App-header">
        <div style={fixedHeaderCss}>
          <div style={headerInnerCss}>
            <UIImage src={"/logo-landscape.svg"} width={"auto"} height={"32px"}/>
            <div style={buttonAreaCss}>
              {props.children ?? undefined}
            </div>
          </div>
        </div>
        <div style={headerSpaceCss}></div>
      </header>
    </React.Fragment>
  )
}

export default AppHeader;