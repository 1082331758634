export const AppConfig = process.env.NODE_ENV === "production"
  ? {
    debug: false,
    url: "https://www.cloudorder.jp",
    firestore: "prod-order",
    orderStorage: "prod/order",
    preOrderStorage: "prod/pre-order",
  } : {
    debug: true,
    url: "http://localhost:3000",
    firestore: "dev-order",
    orderStorage: "dev/order",
    preOrderStorage: "dev/pre-order",
  }
