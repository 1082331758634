import IMerchandise from "./IMerchandise";
import IPurchasable from "../purchasable/IPurchasable";
import TotalPrice from "../TotalPrice";
import CartDetail from "../../../main/model/cart/CartDetail";
import SimplePurchasable from "../purchasable/SimplePurchasable";
import PaymentType from "../../enum/PaymentType";
import {OrderItem} from "../../../main/model/order/Order";
import {ProductTableRowInput} from "../../../main/dom/order/MSTableInput";

class SelectableMerchandise implements IMerchandise {
  readonly title: string
  readonly description: string
  readonly imageUrl: string
  readonly notes: string[] = []

  isSelected: boolean = false
  selectedIndex: number = -1
  purchasables: IPurchasable[]

  get purchasable(): IPurchasable | null {
    if (!this.isSelected) return null
    return this.purchasables[this.selectedIndex]
  }

  constructor(title: string, description: string, imageUrl: string, purchasables: SimplePurchasable[]) {
    this.title = title
    this.description = description
    this.imageUrl = imageUrl
    this.purchasables = purchasables
  }

  calcPrice(): TotalPrice {
    if (!this.purchasable) return TotalPrice.empty
    return this.purchasable.calcPrice()
  }

  toCardDetail(): CartDetail[] {
    if (!this.purchasable) return []
    return [new CartDetail(this.title, this.imageUrl, this.description, this.purchasable)]
  }

  toOrderItem(paymentType: PaymentType, ...args: string[]): OrderItem[] {
    if (this.purchasable?.paymentType === paymentType) {
      const pur = this.purchasable
      return [new ProductTableRowInput([...args, this.title, pur.name].filter(_ => _).join(" "), pur.price, pur.quantity)]
    }
    return []
  }
}

export default SelectableMerchandise