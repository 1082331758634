import React from "react";
import {TInitialItem} from "../../../model/product/initial/IInitial";
import UIImage from "../../../../shared/dom/component/UIImage";
import UILabel from "../../../../shared/dom/component/UILabel";
import UIPurchasablePrice from "../../component/UIPurchasablePrice";
import {RectProps} from "../../../../shared/model/props/RectProps";
import DoubleInitialItem from "../../../model/product/initial/DoubleInitialItem";

type MSInitialItemProp = {
  item: TInitialItem
} & RectProps

const MSInitialItem: React.FC<MSInitialItemProp> = (props) => {

  const css: { [_: string]: React.CSSProperties } = {
    container: {
      width: props.width ?? "100%",
      height: props.height ?? "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    text: {
      textAlign: "center",
      font: "normal normal normal 14px/24px Hiragino Sans",
      letterSpacing: "0px",
      color: " #000000CC",
      opacity: 1,
    },
    titleWrap: {
      marginTop: "3px",
    },
    image: {
      width: "132px",
      height: "132px",
    },
  }

  const buildAdditional = () => {
    if (props.item instanceof DoubleInitialItem) {
      return <div>
        <UILabel style={css.text} label={props.item.additionalPurchasable.name}/>
        <UIPurchasablePrice style={css.text} purchasable={props.item.additionalPurchasable}/>
      </div>
    } else {
      return
    }
  }

  return (
    <div style={css.container}>
      <UIImage width={css.image.width} height={css.image.height} src={props.item.imageUrl}/>
      <div style={css.titleWrap}>
        <UILabel style={css.text} label={props.item.title}/>
      </div>
      <UILabel style={css.text} label={props.item.description}/>
      <UIPurchasablePrice style={css.text} purchasable={props.item.purchasable}/>
      {buildAdditional()}
    </div>
  );
}

export default MSInitialItem;