import React, {useId} from "react";
import UIUnit from "./UIUnit";
import StyleProps from "../../model/props/StyleProps";

type UIImageProp = {
  label: string
  checked?: boolean
  onChange?: (_: boolean) => void
  boxPosition?: string
} & StyleProps

const UICheckBox: React.FC<UIImageProp> = (props) => {
  const uid = useId();
  const isRight = (props.boxPosition ?? "right") === "right";
  const label = <label htmlFor={uid}>{props.label}</label>;

  const css: { [_: string]: React.CSSProperties } = {
    leftLabel: {
      marginRight: "5px"
    },
    rightLabel: {
      marginLeft: "5px"
    }
  }

  return (
    <React.Fragment>
      <UIUnit tag={"div"} style={props.style}>
        {isRight && <span style={css.leftLabel}>{label}</span>}
        <input type="checkbox" id={uid} name={uid}
               defaultChecked={props.checked ?? false}
               onChange={event => props.onChange?.(event.target.checked)}/>
        {!isRight && <span style={css.rightLabel}>{label}</span>}
      </UIUnit>
    </React.Fragment>
  );
}

export default UICheckBox;