import React from "react"
import {auth} from "../config/FirebaseApp"
import {GoogleAuthProvider, signInWithRedirect, signOut} from "firebase/auth"

const googleSignIn = (): Promise<void> => {
  const provider = new GoogleAuthProvider()
  provider.setCustomParameters({
    "hd": "lacicu.co.jp",
  })
  return signInWithRedirect(auth, provider).then()
}

export const googleSignOut = (): Promise<void> => {
  return signOut(auth)
}

const GoogleSignIn = () => {
  return (
    <div>
      <div>GoogleSignIn</div>
      <img src={"/google/signInButton.png"} onClick={googleSignIn}/>
    </div>
  )
}

export default GoogleSignIn