import IMerchandise from "./IMerchandise";
import IPurchasable from "../purchasable/IPurchasable";
import PaymentType from "../../enum/PaymentType";
import TotalPrice from "../TotalPrice";
import CartDetail from "../../../main/model/cart/CartDetail";
import SelectionType from "../../enum/SelectionType";
import SimplePurchasable from "../purchasable/SimplePurchasable";
import {OrderItem} from "../../../main/model/order/Order";
import {ProductTableRowInput} from "../../../main/dom/order/MSTableInput";

class SingleMerchandise implements IMerchandise {
  readonly title: string
  readonly description: string
  readonly imageUrl: string
  readonly notes: string[]
  purchasables: IPurchasable[] // 一個しかないのでpurchasableを使うこと

  get purchasable(): IPurchasable {
    return this.purchasables[0]
  }

  protected constructor(title: string, description: string, imageUrl: string, notes: string[], name: string, paymentType: PaymentType, price: number, selectionType: SelectionType) {
    this.description = description
    this.imageUrl = imageUrl
    this.notes = notes
    this.title = title
    this.purchasables = [new SimplePurchasable(name, paymentType, price, selectionType)]
  }

  calcPrice(): TotalPrice {
    return this.purchasable.calcPrice()
  }

  toCardDetail(): CartDetail[] {
    return [new CartDetail(this.title, this.imageUrl, this.description, this.purchasable)]
  }

  toOrderItem(paymentType: PaymentType, ...args: string[]): OrderItem[] {
    return this.purchasables
      .filter(_ => _.paymentType === paymentType)
      .map(_ => new ProductTableRowInput([...args, this.title].filter(_ => _).join(" "), _.price, _.quantity))
  }
}

export default SingleMerchandise;