import React, {FC} from 'react';
import UILabel from "../../../shared/dom/component/UILabel";
import {Order} from "../../model/order/Order";

type MSOSCartIPreviewProps = {
  order?: Order
}
const MSOSCartPreview: FC<MSOSCartIPreviewProps> = (props) => {
  const css: { [_: string]: React.CSSProperties } = {
    date:{
      display:"flex",
      paddingLeft:"220px",
      fontSize:"14px"
    },
    address:{
      marginTop:"40px",
      display:"flex",
      fontSize:"14px"
    },
    corporate:{
      marginTop:"30px",
      display:"flex",
      fontSize:"14px"
    },
    department:{
      marginTop:"30px",
      display:"flex",
      fontSize:"14px"
    },
    manager:{
      marginTop:"10px",
      display:"flex",
      fontSize:"14px"
    },
    right: {
      marginLeft:"12px",
      borderBottom:"1px solid #000",
      width:"340px"
    },
    departmentRight:{
      marginLeft:"12px",
      width:"340px"
    }
  }
  return (
    <React.Fragment>
      <div style={css.date}>
        <UILabel label={"注文日: "}/>
        <UILabel label={props.order?.confirmAt ?? ""}/>
      </div>
      <div style={css.address}>
        <UILabel label={"住所　　"}/>
        <div style={css.right}>
          <UILabel label={props.order?.confirmCompanyAddress ?? ""}/>
        </div>
      </div>
      <div style={css.corporate}>
        <UILabel label={"法人名　"}/>
        <div style={css.right}>
          <UILabel label={props.order?.confirmCompanyName ?? ""}/>
        </div>
      </div>
      <div style={css.department}>
        <UILabel label={"部署名　"}/>
        <div style={css.departmentRight}>
          <UILabel label={props.order?.confirmCompanyDepartment ?? ""}/>
        </div>
      </div>
      <div style={css.manager}>
        <UILabel label={"担当者名"}/>
        <div style={css.right}>
          <UILabel label={props.order?.confirmCompanyManager ?? ""}/>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MSOSCartPreview
