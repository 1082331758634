type OrderMailProps = {
  to: string
  companyName: string
  companyManager: string
  orderLink: string
}
export const OrderMail = (props: OrderMailProps) => {
  return {
    to: props.to,
    message: {
      subject: "【株式会社Lacicu】御見積書送付の件につきまして",
      html: `${props.companyName}<br>
${props.companyManager}様<br>
<br>
平素よりお世話になっております。<br>
この度は弊社商品のお見積もりをご依頼いただき、ありがとうございます。<br>
見積書をWEB上にて発行いたしましたのでお知らせ致します。<br>
お手数ですが下記のURLよりご査収のほどよろしくお願い申し上げます。<br>
<br>
【見積書発行・注文書発行URL】<br>
<a href="${props.orderLink}">見積書を表示する</a><br>
<br>
※上記URLより見積書をダウンロードしてください。<br>
<br>
<br>
見積書の内容をご確認いただき、問題がなければ<br>
上記URLから注文書の入力フォームをご入力いただけますと幸いです。<br>
<br>
お取引完了までの間、どうぞよろしくお願い申し上げます。<br>
<br>
<hr>
【株式会社Lacicu】<br>
 住所／　〒101-0031　東京都千代田区東神田1‐2‐10　泰岳ビル5階<br>
 HP：https://lacicu.co.jp/<br>
 TEL: 03‐5829‐6992　／　FAX: 03‐5829‐6772<br>
 営業時間：平日10時から19時まで<br>
<hr>`,
    },
  }
}