import {IOption, TOptionItem} from "./IOption";
import TotalPrice from "../../../../shared/model/TotalPrice";
import PaymentType from "../../../../shared/enum/PaymentType";
import {OrderItem} from "../../order/Order";

class Option implements IOption {
  items: TOptionItem[];

  constructor(options: TOptionItem[]) {
    this.items = options;
  }

  calcPrice(): TotalPrice {
    return this.items.filter(_ => _.isInCart).reduce((prev: TotalPrice, cur) => prev.add(cur.calcPrice()), TotalPrice.empty);
  }

  toOrderItem(paymentType: PaymentType, ...args: string[]): OrderItem[] {
    return this.items.filter(_ => _.isInCart).flatMap(_ => _.toOrderItem(paymentType, ...args))
  }
}

export default Option

