import {createElement, FC, Fragment} from "react";
import ChildrenProps from "../../model/props/ChildrenProps";
import TagProps from "../../model/props/TagProps";
import StyleProps from "../../model/props/StyleProps";

type UIUnitProps = ChildrenProps & TagProps & StyleProps

const UIUnit: FC<UIUnitProps> = (props) => {
  return (
    <Fragment>
      {createElement(props.tag!, {style: props.style}, props.children)}
    </Fragment>
  );
}

export default UIUnit;