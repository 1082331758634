class UUID {
  static generate(): string {
    return Array.apply(null, Array(32))
      .map((_, index) => {
        let piece = "";
        const random = Math.random() * 16 | 0;
        if (index === 8 || index === 12 || index === 16 || index === 20) {
          piece += "-"
        }
        piece += (index === 12 ? 4 : (index === 16 ? ((random & 3) | 8) : random))
        return piece
      }).join("")
  }
}

export default UUID