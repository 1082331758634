import React, {ChangeEvent, FC, useEffect, useState} from "react";
import ProductUtils from "../../../shared/utils/ProductUtils";
import UIButton from "../../../shared/dom/component/UIButton";
import PaymentType from "../../../shared/enum/PaymentType";
import {OrderInput, OrderItem} from "../../model/order/Order";
import {MemoTableRow, ProductTableRow, RowType} from "../confirm/MSTable";

const css: { [_: string]: React.CSSProperties } = {
  tableTitle: {
    paddingTop: "30px",
    paddingBottom: "10px",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%"
  },
  th1: {
    fontWeight: "normal",
    backgroundColor: "#aaa",
    textAlign: "left",
    paddingLeft: "20px"
  },
  th2: {
    fontWeight: "normal",
    backgroundColor: "#aaa",
    textAlign: "center",
    width: "140px"
  },
  th3: {
    fontWeight: "normal",
    backgroundColor: "#aaa",
    textAlign: "center",
    width: "80px"
  },
  th4: {
    fontWeight: "normal",
    backgroundColor: "#aaa",
    textAlign: "center",
    width: "140px"
  },
  th5: {
    fontWeight: "normal",
    backgroundColor: "#aaa",
    width: "40px"
  },
  inputCss: {
    width: "100%",
    height: "38px",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: "1px solid #DEDEDF",
    borderRadius: "4px",
    marginTop: "4px"
  }
}

interface ISelectableTableRow {
  isSelected: boolean
}

class ProductTableRowInput extends ProductTableRow implements ISelectableTableRow {
  isSelected: boolean = true

  static get empty(): ProductTableRowInput {
    return new ProductTableRowInput("", 0, 1)
  }
}

type MSProductTableRowInputProp = {
  onRemove: () => void
  paymentType: PaymentType
  itemId: string
  order: OrderInput
  useEffectCb?: () => void
}

const MSProductTableRowInput: FC<MSProductTableRowInputProp> = (props) => {
  const order = props.order
  const row = order.detailOf(props.paymentType).get(props.itemId) as ProductTableRow

  let [price, setPrice] = useState(row.price)
  let [quantity, setQuantity] = useState(row.quantity)

  useEffect(() => {
    props.useEffectCb?.()
  }, [price, quantity])

  function selectAll(event: ChangeEvent<HTMLInputElement>) {
    event.target.select()
  }

  function setPropName(event: ChangeEvent<HTMLInputElement>) {
    row.name = event.target.value
  }

  function setPropPrice(event: ChangeEvent<HTMLInputElement>) {
    const number = Number(event.target.value)
    if (number) {
      row.price = number
      setPrice(row.price)
    }
  }

  function setPropQuantity(event: ChangeEvent<HTMLInputElement>) {
    const number = Number(event.target.value)
    if (!isNaN(number)) {
      row.quantity = number
      setQuantity(row.quantity)
    }
  }

  return (
    <tr>
      <td>
        <input placeholder={"品目"} type={"text"} defaultValue={row.name}
               onChange={setPropName} onFocus={selectAll}/>
      </td>
      <td>
        <input placeholder={"金額"} type={"number"} defaultValue={price}
               onChange={setPropPrice} onFocus={selectAll}/>
      </td>
      <td>
        <input placeholder={"数量"} type={"number"} defaultValue={quantity}
               onChange={setPropQuantity} onFocus={selectAll}/>
      </td>
      <td>
        {ProductUtils.formatPrice(price * quantity)}
      </td>
      <td>
        <button onClick={props.onRemove}>×</button>
      </td>
    </tr>
  )
}

class MemoTableRowInput extends MemoTableRow implements ISelectableTableRow {
  isSelected: boolean = true

  static get empty(): MemoTableRowInput {
    return new MemoTableRowInput("")
  }
}

type MSMemoTableRowInputProp = {
  onRemove: () => void
  paymentType: PaymentType
  itemId: string
  order: OrderInput
}

const MSMemoTableRowInput: FC<MSMemoTableRowInputProp> = (props) => {
  const order = props.order
  const row = order.detailOf(props.paymentType).get(props.itemId) as MemoTableRow

  let [memo, setMemo] = useState(row.memo)

  function selectAll(event: ChangeEvent<HTMLInputElement>) {
    event.target.select()
  }

  function setPropMemo(event: ChangeEvent<HTMLInputElement>) {
    row.memo = event.target.value
    setMemo(row.memo)
  }

  return (
    <tr>
      <td colSpan={4}>
        <input placeholder={"備考"} type={"text"} defaultValue={memo}
               onChange={setPropMemo} onFocus={selectAll}/>
      </td>
      <td>
        <button onClick={props.onRemove}>×</button>
      </td>
    </tr>
  )
}

type MSTableProp = {
  paymentType: PaymentType
  order: OrderInput
  useEffectCb?: () => void
}

const MSTableInput: FC<MSTableProp> = (props) => {
  const order = props.order

  const Header = () => {
    switch (props.paymentType) {
      case PaymentType.BUYOUT:
        return <div style={css.tableTitle}>御見積詳細（初期費用）</div>
      case PaymentType.SUBSCRIPTION:
        return <div style={css.tableTitle}>御見積詳細（月額費用）</div>
    }
  }

  const TableHeader = () => {
    return (
      <tr>
        <th style={css.th1}>品目</th>
        <th style={css.th2}>単価</th>
        <th style={css.th3}>数量</th>
        <th style={css.th4}>金額（税抜）</th>
        <th style={css.th5}></th>
      </tr>
    )
  }

  let [items, setItems] = useState([...order.detailOf(props.paymentType).items])

  function removeItem(item: OrderItem) {
    setItems(order.detailOf(props.paymentType).remove(item))
    props.useEffectCb?.()
  }

  return (
    <div>
      <Header/>
      <table style={css.table}>
        <thead>
        <TableHeader/>
        </thead>
        <tbody>
        {items.map(item =>
          <MSTableRowInput order={props.order}
                           key={item.id} itemId={item.id}
                           paymentType={props.paymentType}
                           onRemove={() => removeItem(item)}
                           useEffectCb={props.useEffectCb}/>)}
        </tbody>
      </table>
      <div style={css.buttonArea}>
        <UIButton label={"商品を追加"} onClick={() => {
          order.detailOf(props.paymentType).add(ProductTableRowInput.empty)
          setItems([...order.detailOf(props.paymentType).items])
        }}/>
        <UIButton label={"メモを追加"} onClick={() => {
          order.detailOf(props.paymentType).add(MemoTableRowInput.empty)
          setItems([...order.detailOf(props.paymentType).items])
        }}/>
      </div>
    </div>
  )
}

type MSTableRowInputProp = {
  onRemove: () => void
  paymentType: PaymentType
  itemId: string
  order: OrderInput
  useEffectCb?: () => void
}

const MSTableRowInput: FC<MSTableRowInputProp> = (props) => {
  const order = props.order

  const buildRow = () => {
    const row = order.detailOf(props.paymentType).get(props.itemId)
    if (!row) return null
    if (row.type === RowType.PRODUCT) {
      return <MSProductTableRowInput order={props.order}
                                     itemId={row.id}
                                     paymentType={props.paymentType}
                                     onRemove={props.onRemove}
                                     useEffectCb={props.useEffectCb}/>
    } else if (row.type === RowType.MEMO) {
      return <MSMemoTableRowInput order={props.order}
                                  itemId={row.id}
                                  paymentType={props.paymentType}
                                  onRemove={props.onRemove}/>
    }
  }
  return (
    <React.Fragment>
      {buildRow()}
    </React.Fragment>
  )
}

export {MSTableInput, ProductTableRowInput, MemoTableRowInput}