import {CSSProperties} from "react";

class GlobalCss {
  static sectionTitle: CSSProperties = {
    font: "italic normal bold 34px/30px Lato",
    letterSpacing: "2.72px",
    color: "#000000DE",
    opacity: 1,
  }
}

export default GlobalCss