import IPurchasable from "../../../shared/model/purchasable/IPurchasable";

class CartDetail {
  title: string
  image: string
  description: string
  purchasable: IPurchasable

  constructor(title: string, image: string, description: string, purchasable: IPurchasable) {
    this.title = title;
    this.image = image;
    this.description = description;
    this.purchasable = purchasable;
  }
}

export default CartDetail;