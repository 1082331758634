type ConfirmMailProps = {
  to: string
  companyName: string
  companyManager: string
}
export const ConfirmMail = (props: ConfirmMailProps) => {
  return {
    to: props.to,
    message: {
      subject: "【株式会社Lacicu】ご注文承りました。",
      html: `${props.companyName}<br>
${props.companyManager}様<br>
<br>
お世話になっております。<br>
株式会社Lacicuでございます。<br>
この度は弊社商品をご注文いただき<br>
誠にありがとうございます。<br>
<br>
ご注文を承りましたので、<br>
今後は担当より契約書を送付させていただきます。<br>
<br>
今後とも宜しくお願い致します。<br>
<br>
<hr>
【株式会社Lacicu】<br>
 住所／　〒101-0031　東京都千代田区東神田1‐2‐10　泰岳ビル5階<br>
 HP：https://lacicu.co.jp/<br>
 TEL: 03‐5829‐6992　／　FAX: 03‐5829‐6772<br>
営業時間：平日10時から19時まで<br>
<hr>`,
    },
  }
}