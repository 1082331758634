import React from "react";
import {useNavigate} from "react-router-dom";
import AllProduct from "../../main/model/product/AllProduct";

type AppHeaderButtonProp = {
  label: string
  to: string
  allProduct?: AllProduct
}

const AppHeaderButton = (props: AppHeaderButtonProp) => {
  const navigation = useNavigate()
  const frameCss: React.CSSProperties = {
    /* Layout Properties */
    width: "88px",
    height: "25px",
    /* UI Properties */
    cursor: "pointer",
    border: "1px solid #1B1C1D",
    borderRadius: "4px",
    opacity: 1,
    font: "normal normal normal 13px/23px Hiragino Sans",
    color: " #1B1C1D",
    backgroundColor: "transparent",
  }

  const to = () => {
    navigation(props.to, {state: props.allProduct})
  }

  return (
    <button style={frameCss} onClick={to}>{props.label}</button>
  )
}

export default AppHeaderButton;