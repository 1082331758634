import ComparablePlanItem from "./ComparablePlanItem";
import AdditionalPlanItem from "./AdditionalPlanItem";
import IPurchasableProtocol from "../../../../shared/model/purchasable/IPurchasableProtocol";
import TotalPrice from "../../../../shared/model/TotalPrice";
import SimplePlanItem from "./SimplePlanItem";
import PaymentType from "../../../../shared/enum/PaymentType";
import {OrderItem} from "../../order/Order";
import ICart from "../../../../shared/model/ICart";

interface iPlanItem {
  memo: string
}

type IPlanItem = iPlanItem & ICart


type TPlanItem = SimplePlanItem | AdditionalPlanItem | ComparablePlanItem

interface iPlanItems {
  // fields
  name: string
  items: TPlanItem[]
}

type IPlanItems = iPlanItems & IPurchasableProtocol

class PlanItems implements IPlanItems {

  name: string
  items: TPlanItem[]

  constructor(name: string = "", items: TPlanItem[]) {
    this.name = name
    this.items = items
  }

  calcPrice(): TotalPrice {
    return this.items.filter(_ => _.isInCart).reduce((prev: TotalPrice, cur) => prev.add(cur.calcPrice()), TotalPrice.empty)
  }

  toOrderItem(paymentType: PaymentType, ...args: string[]): OrderItem[] {
    return this.items.filter(_ => _.isInCart).flatMap(_ => _.toOrderItem(paymentType, ...args))
  }
}

interface iPlan {
  // fields
  selects: IPlanItems[]
  isSelected: boolean
  selectedIndex: number

  // getter
  items: TPlanItem[]
}

type IPlan = iPlan
  & IPurchasableProtocol
// & ICart

export {PlanItems};
export type {IPlan, IPlanItems, TPlanItem, IPlanItem};

