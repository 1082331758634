import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {db, storage} from "../../shared/config/FirebaseApp";
import {doc, getDoc, updateDoc} from 'firebase/firestore';
import {getDownloadURL, ref} from "firebase/storage";
import {Order} from "../model/order/Order";
import DateUtils from "../../shared/utils/DateUtils";
import UIButton from "../../shared/dom/component/UIButton";
import MSOSConfirmPreview from "./ordersheet/MSOSConfirmPreview";
import MSOSConfirmInput from "./ordersheet/MSOSConfirmInput";
import MSOSCartPreview from "./ordersheet/MSOSCartIPreview";
import {AppConfig} from "../../shared/config/AppConfig";
import FirebaseUtils, {OrderStatus} from "../../shared/utils/FirebaseUtils";
import PdfUtils from "../../shared/utils/PdfUtils";
import {ConfirmMail} from "../../data/mail/ConfirmMail";
import Logger from "../../shared/Logger";
import UILoading from "../../shared/dom/UILoading";

enum ConfirmOrderState {
  PREPARE,
  PRINTING,
  SAVING,
  SENT,
  CONFIRMED
}

const MSPageConfirmOrder = () => {
  let [state, setState] = useState(ConfirmOrderState.PREPARE)

  const {orderId} = useParams()
  const docRef = doc(db, AppConfig.firestore, orderId as string)

  let [srcUrl, setSrcUrl] = useState("")
  let [order, setOrder] = useState<Order | undefined>(undefined)
  let [isPreview, setIsPreview] = useState(false)

  useEffect(() => {
    getDoc(docRef).then(docSnap => {
      if (docSnap.exists()) {
        Logger.debug("docSnap.data()", docSnap.data())
        if (docSnap.data().status === OrderStatus.CONFIRMED) {
          return setState(ConfirmOrderState.CONFIRMED)
        }

        const order = JSON.parse(docSnap.data().data) as Order
        order.confirmAt = DateUtils.toJpnYMD(new Date())
        setOrder(order)

        updateDoc(docRef, {status: OrderStatus.READ}).then()

        // Create a reference with an initial file path and name
        const storageRef = ref(storage, docSnap.data().preOrderPdfPath)
        getDownloadURL(storageRef).then(async (url) =>
          setSrcUrl(url)
        )
      } else {
        console.log("No such document!")
        // TODO: move to 404
      }
    })
  }, [orderId])

  const confirmOrder = async () => {
    if (!order) return

    setState(ConfirmOrderState.PRINTING)
    const pdf = await PdfUtils.toPdf("divToPrint")
    const blob = pdf?.output("blob")
    if (!blob) return

    setState(ConfirmOrderState.SAVING)
    const filePath = `${AppConfig.orderStorage}/${FirebaseUtils.generateFileName()}.pdf`
    await FirebaseUtils.uploadPdf(blob, filePath)

    await updateDoc(docRef, {
      orderPdfPath: filePath,
      confirmedAt: Date.now(),
      status: OrderStatus.CONFIRMED,
    })

    const mail = ConfirmMail({
      to: order.companyEmail,
      companyName: order.companyName,
      companyManager: order.companyManager,
    })
    await FirebaseUtils.sendMail(mail)
    setState(ConfirmOrderState.SENT)
    pdf?.save("注文書.pdf")
  }

  const css: { [_: string]: React.CSSProperties } = {
    container: {
      paddingTop: "50px"
    },
    pdf: {
      backgroundColor: "#fff",
      border: "1px solid #000",
      width: "210mm",
      minHeight: "297mm",
      marginLeft: "auto",
      marginRight: "auto",
    },
    title: {
      textAlign: "center",
      fontSize: "30px",
      paddingTop: "30px"
    },
    lead: {
      textAlign: "center",
      fontSize: "20px",
      marginTop: "140px"
    },
    buttons: {
      width: "210mm",
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop: "20px",
      display: "flex",
      justifyContent: "space-between"
    },
    button: {
      width: "88px",
      height: "25px",
      border: "1px solid rgb(27, 28, 29)",
      borderRadius: "4px",
      opacity: 1,
      font: "13px / 23px Hiragino Sans",
      color: "rgb(27, 28, 29)",
      backgroundColor: "transparent",
      cursor: "pointer",
    },
    orderButton: {
      width: "88px",
      height: "25px",
      border: "1px solid rgb(27, 28, 29)",
      borderRadius: "4px",
      opacity: 1,
      font: "13px / 23px Hiragino Sans",
      color: "rgb(27, 28, 29)",
      backgroundColor: "transparent",
      cursor: "pointer",
      marginLeft: "20px"
    }
  }

  return (
    <React.Fragment>
      <div style={css.container}>
        {order && state === ConfirmOrderState.PREPARE && (
          isPreview ?
            <React.Fragment>
              <div id="divToPrint" style={css.pdf}>
                <MSOSConfirmPreview order={order}
                                    orderRightChild={<MSOSCartPreview order={order}/>}/>
              </div>
              <div style={css.buttons}>
                <div>
                  <UIButton style={css.button} label={"修正する"} onClick={() => setIsPreview(false)}/>
                  <UIButton style={css.orderButton} label={"注文する"} onClick={confirmOrder}/>
                </div>
              </div>
            </React.Fragment>
            :
            <React.Fragment>
              <div style={css.pdf}>
                <MSOSConfirmPreview order={order}
                                    orderRightChild={<MSOSConfirmInput order={order}/>}/>
              </div>
              <div style={css.buttons}>
                <a href={srcUrl} download={`${DateUtils.toJpnYM(new Date())}_注文書.pdf`}>
                  白紙の注文書をダウンロード
                </a>
                <UIButton style={css.button} label={"確認する"} onClick={() => {
                  let message = []
                  const _order = order as Order
                  if (!_order.confirmCompanyAddress) {
                    message.push("住所")
                  }
                  if (!_order.confirmCompanyName) {
                    message.push("会社名")
                  }
                  if (!_order.confirmCompanyManager) {
                    message.push("担当者名")
                  }
                  if (message.length > 0) {
                    alert(`入力された以下の情報に不備があります。確認をお願いします\n [${message.join(", ")}]`)
                    return
                  }
                  setIsPreview(true)
                }}/>
              </div>
            </React.Fragment>
        )}
      </div>
      {order && [ConfirmOrderState.PRINTING, ConfirmOrderState.SAVING, ConfirmOrderState.SENT].includes(state) &&
          <div>
              <div style={css.title}>
                {state === ConfirmOrderState.PRINTING && "出力中..."}
                {state === ConfirmOrderState.SAVING && "出力中..."}
                {state === ConfirmOrderState.SENT && "出力中..."}
              </div>
              <UILoading loading={[ConfirmOrderState.PRINTING, ConfirmOrderState.SAVING].includes(state)}/>
              <div style={css.lead}>
                {state === ConfirmOrderState.PRINTING && "注文書を作成しています。"}
                {state === ConfirmOrderState.SAVING && "注文情報を保存しています。"}
                {state === ConfirmOrderState.SENT && "注文ありがとうございます。完了のメールを送信しました。"}
              </div>
          </div>
      }
      {state === ConfirmOrderState.CONFIRMED &&
          <div>
              <div style={css.title}>お探しのページは見つかりませんでした</div>
          </div>
      }
    </React.Fragment>
  )
}
export default MSPageConfirmOrder
