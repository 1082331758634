import PaymentType from "../../../../shared/enum/PaymentType";
import SimpleMerchandise from "../../../../shared/model/merchandise/SimpleMerchandise";
import SelectionType from "../../../../shared/enum/SelectionType";
import ICart from "../../../../shared/model/ICart";

class SimpleOptionItem extends SimpleMerchandise implements ICart {
  isInCart: boolean = false

  constructor(title: string, description: string, imageUrl: string, paymentType: PaymentType, price: number, selectionType: SelectionType) {
    super(title, description, imageUrl, [], "", paymentType, price, selectionType)
  }
}

export default SimpleOptionItem