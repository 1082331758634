import React, {ReactNode, useState} from 'react';
import UIImage from "../../../shared/dom/component/UIImage";
import UITextField from "../../../shared/dom/component/UITextField";
import MSDetailSumInput from "../order/MSDetailSumInput";
import UIDateField from "../../../shared/dom/component/UIDateField";
import DateUtils from "../../../shared/utils/DateUtils";
import {MSTableInput} from "../order/MSTableInput";
import {OrderInput} from "../../model/order/Order";

type MSOSCartInputProp = {
  orderRightChild: ReactNode
  order: OrderInput
}

const MSOSCartInput: React.FC<MSOSCartInputProp> = (props) => {
  const css: { [_: string]: React.CSSProperties } = {
    pdf: {
      paddingTop: "35px",
      paddingBottom: "40px",
      paddingLeft: "25px",
      paddingRight: "25px"
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "end",
      paddingLeft: "23px",
      paddingRight: "23px",
      paddingBottom: "25px",
      borderBottom: "2px solid #e0e0e0"
    },
    headerTitle: {
      font: "normal bold normal 24px/24px Hiragino Sans",
      letterSpacing: "1.2px",
      color: "#000000DE",
      opacity: 1,
    },
    quote: {
      width: "100%",
      paddingLeft: "23px",
      paddingRight: "23px",
      paddingTop: "25px",
      paddingBottom: "40px"
    },
    quoteCol: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    quoteLeft: {
      width: "60%",
    },
    quoteRight: {
      width: "40%",
    },
    orderCol: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    orderLeft: {
      width: "40%",
    },
    orderRight: {
      width: "60%",
    },
    inputCss: {
      width: "196px",
      height: "38px",
      paddingLeft: "10px",
      paddingRight: "10px",
      border: "1px solid #DEDEDF",
      borderRadius: "4px",
      marginTop: "4px"
    },
    detailSumInputWrapperCss: {
      marginTop: "50px"
    },
    addressArea: {
      marginTop: "10px",
      marginBottom: "10px"
    },
    textAreaTitle: {
      paddingTop: "30px",
      paddingBottom: "10px"
    },
    textArea: {
      width: "100%",
      height: "78px"
    },
    order: {
      paddingTop: "20px",
      paddingLeft: "23px",
      paddingRight: "23px",
    },
    orderDateArea: {
      marginTop: "15px"
    },
    orderDateWrapper: {
      marginTop: "10px"
    }
  }

  let [orderObserver, setOrder] = useState(JSON.stringify(props.order))
  const useEffectCb = () => {
    setOrder(JSON.stringify(props.order))
  }

  return (
    <React.Fragment>
      <div style={css.pdf}>
        <div>
          {/*header*/}
          <div style={css.header}>
            <div style={css.headerTitle}>御見積書</div>
            <UIImage height={"29px"} width={"auto"} src={"/logo-landscape.png"}></UIImage>
          </div>
          {/*body*/}
          <div style={css.quote}>
            <div style={css.quoteCol}>
              <div style={css.quoteLeft}>
                <UITextField required={true} inputCss={css.inputCss} default={props.order.companyName}
                             label={"会社名"} placeholder={"株式会社〇〇"}
                             onChange={(value) => props.order.companyName = value}/>
                <div style={css.detailSumInputWrapperCss}>
                  {orderObserver && <MSDetailSumInput order={props.order}/>}
                </div>
              </div>
              <div style={css.quoteRight}>
                <UIDateField inputCss={css.inputCss} label={"発行日"}
                             default={props.order.orderAtDate}
                             onChange={(value) => {
                               props.order.orderAtDate = value
                               props.order.orderAt = DateUtils.toJpnYMD(props.order.orderAtDate)
                             }}/>
                <div style={css.addressArea}>
                  <div>〒101-0031</div>
                  <div>東京都千代田区東神田1-2-10</div>
                  <div>泰岳ビル 5階</div>
                  <div>株式会社ラシク</div>
                  <div>TEL: 03-5829-6992</div>
                </div>
                <UITextField inputCss={css.inputCss} default={props.order.managerName}
                             label={"担当"} placeholder={"担当者名"}
                             onChange={(value) => props.order.managerName = value}/>
                <UITextField inputCss={css.inputCss} default={props.order.managerEmail}
                             label={"E-mail"} placeholder={"メールアドレス"}
                             onChange={(value) => props.order.managerEmail = value}/>
              </div>
            </div>
            {props.order.details.map(detail =>
              <MSTableInput order={props.order} key={detail.id}
                            paymentType={detail.paymentType} useEffectCb={useEffectCb}/>)}
            <div>
              <div style={css.textAreaTitle}>
                備考
              </div>
              <textarea style={css.textArea} defaultValue={props.order.memo}
                        onChange={(event) => props.order.memo = event.target.value}/>
            </div>
          </div>
        </div>
        <div>
          {/*header*/}
          <div style={css.header}>
            <div style={css.headerTitle}>注文書</div>
          </div>
          {/*body*/}
          <div style={css.order}>
            <div style={css.orderCol}>
              <div style={css.orderLeft}>
                <div>株式会社Lacicu 宛</div>
                <div>上記の通り注文いたします</div>
                <div style={css.orderDateArea}>
                  <UIDateField inputCss={css.inputCss} default={props.order.startFromDate}
                               label={"ご利用開始月"}
                               onChange={(value) => {
                                 props.order.startFromDate = value
                                 props.order.startFrom = DateUtils.toJpnYM(props.order.startFromDate)
                               }}/>
                  <div style={css.orderDateWrapper}>
                    <UIDateField inputCss={css.inputCss} default={props.order.payingFromDate}
                                 label={"月額費用発生月"}
                                 onChange={(value) => {
                                   props.order.payingFromDate = value
                                   props.order.payingFrom = DateUtils.toJpnYM(props.order.payingFromDate)
                                 }}/>
                  </div>
                </div>
              </div>
              <div style={css.orderRight}>
                {props.orderRightChild}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MSOSCartInput
